// FacultyPage.js
import React, { useState, useEffect } from 'react';
import p101 from '../images/PACE_Faculty/PHYSICS/SUNIL P.jpg';
import p102 from '../images/PACE_Faculty/PHYSICS/RONEY.jpg';
import p103 from '../images/PACE_Faculty/PHYSICS/SREESHA.jpg';
import p104 from '../images/PACE_Faculty/PHYSICS/NETHRA.jpg';
import p106 from '../images/PACE_Faculty/PHYSICS/ADITYA.jpg';
import p107 from '../images/PACE_Faculty/PHYSICS/SAMANWITHA.jpg';
import p108 from '../images/PACE_Faculty/PHYSICS/NAGARAJ.jpg';
import p109 from '../images/PACE_Faculty/PHYSICS/SEEMA.jpg';
import p110 from '../images/PACE_Faculty/PHYSICS/SACHIN.jpg';
import p111 from '../images/PACE_Faculty/PHYSICS/ANUPAMA.jpg';
import p112 from '../images/PACE_Faculty/PHYSICS/SAHANA.jpg';
import p113 from '../images/PACE_Faculty/PHYSICS/Maruthi.jpg';

import m201 from '../images/PACE_Faculty/MATHS/SRI LAKHMI.jpg';
import m202 from '../images/PACE_Faculty/MATHS/ARJUN.jpg';
import m203 from '../images/PACE_Faculty/MATHS/RAKSHIT.jpg';
import m204 from '../images/PACE_Faculty/MATHS/ABHISHEK.jpg';
import m205 from '../images/PACE_Faculty/MATHS/vikas.jpeg';
import m206 from '../images/PACE_Faculty/MATHS/ANOOP.jpg';
import m207 from '../images/PACE_Faculty/MATHS/GIREESH.jpg';
import m208 from '../images/PACE_Faculty/MATHS/HARISH.jpg';

import c300 from '../images/principal.png'
import c301 from '../images/PACE_Faculty/CHEMISTRY/KESHAV MURTHY.jpg'
import c302 from '../images/PACE_Faculty/CHEMISTRY/RAMYA.jpg'
import c303 from '../images/PACE_Faculty/CHEMISTRY/APARNA.jpg'
import c304 from '../images/PACE_Faculty/CHEMISTRY/SNEHA.jpg'
import c305 from '../images/PACE_Faculty/CHEMISTRY/PRAVEEN.jpg'
import c306 from '../images/PACE_Faculty/CHEMISTRY/JNANASHREE.jpg'
import c307 from '../images/PACE_Faculty/CHEMISTRY/VASANTH.jpg'
import c308 from '../images/PACE_Faculty/CHEMISTRY/SUCHITHRA.jpg'
import c309 from '../images/PACE_Faculty/CHEMISTRY/Divya.jpeg'
import c310 from '../images/PACE_Faculty/CHEMISTRY/SREEVIDYA.jpg'
import c311 from '../images/PACE_Faculty/CHEMISTRY/ABHISHEK P.jpg'
import c312 from '../images/PACE_Faculty/CHEMISTRY/Nagendra.jpeg'

import b401 from '../images/PACE_Faculty/BIOLOGY/CHANDRU.jpg'
import b402 from '../images/PACE_Faculty/BIOLOGY/DIVYA.jpg'
import b403 from '../images/PACE_Faculty/BIOLOGY/PRADEEPA.jpg'
import b404 from '../images/PACE_Faculty/BIOLOGY/NIVEDITHA.jpg'
import b405 from '../images/PACE_Faculty/BIOLOGY/SUNILDATTA.jpg'
import b406 from '../images/PACE_Faculty/BIOLOGY/AMULYA.jpg'
import b407 from '../images/PACE_Faculty/BIOLOGY/SHILPA.jpg'
// import b408 from '../images/PACE_Faculty/BIOLOGY/GURURAJ.jpg'
import b409 from '../images/PACE_Faculty/BIOLOGY/VENKATESH.jpg'
import b410 from '../images/PACE_Faculty/BIOLOGY/SIDHARTHA.jpg'

import cs501 from '../images/PACE_Faculty/COMP/PRABHANJAN.jpg'
import cs502 from '../images/PACE_Faculty/COMP/DEEPAK.jpg'
import cs503 from '../images/PACE_Faculty/COMP/SUVARNA.jpg'

import l601 from '../images/PACE_Faculty/Language/SUSHMA.jpg'
import l602 from '../images/PACE_Faculty/Language/GANESH.jpg'
import l603 from '../images/PACE_Faculty/Language/ANITHA.jpg'
import l604 from '../images/PACE_Faculty/Language/BASAVARAJ.jpg'
import l605 from '../images/PACE_Faculty/Language/CHANDRAPPA.jpg'
import l606 from '../images/PACE_Faculty/Language/MYTHRI.jpg'
import l607 from '../images/PACE_Faculty/Language/NAGESH.jpg'

import o701 from '../images/PACE_Faculty/OTHERS/BABU KUMAR.jpg'
import o702 from '../images/PACE_Faculty/OTHERS/HARISH PATIL.jpg'
import o703 from '../images/PACE_Faculty/OTHERS/SANJAY.jpg'

const departments = ['Physics', 'Maths', 'Chemistry', 'Biology', 'Computers', 'Languages', 'Others'];
const LanguagesOptions = ['English', 'Kannada', 'Hindi', 'Sanskrit']

const facultyData = {
  Physics: [
    { id: 101, name: 'Suneel P', qualification: 'M.Sc.', achievements: '', experience: '19 Years', description: 'Esteemed for HIS contributions to the field, HE is dedicated to nurturing the physicists of tomorrow.', photo: p101 },
    { id: 102, name: 'Roney A Dberta', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '08 Years', description: 'HIS profound belief in the fundamental role of physics in understanding the universe drives HIS commitment to education.', photo: p102 },
    { id: 103, name: '  Dr. Shrisha B V', qualification: 'M.Sc., B.Ed.', achievements: 'Ph.d', experience: '12 Years', description: 'HIS remarkable talent in making intricate physical concepts approachable sets HIM apart.', photo: p103 },
    { id: 104, name: 'Nethra H S', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '08 Years', description: 'Her belief in the transformative potential of physics shapes her dynamic teaching style.', photo: p104 },
    // { id: 105, name: 'Chandana R', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '01 Years', description: 'She is celebrated for her knack in fostering a love for physics among students.', photo: p105 },
    { id: 106, name: 'Adithya H', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '06 Years', description: 'HIS passion for educating students extends beyond the classroom and into the realm of research and discovery.', photo: p106 },
    { id: 107, name: 'Samanwitha K V', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '05 Years', description: 'She inspires students to explore the wonders of the universe, driven by her deep passion for physics.', photo: p107 },
    { id: 108, name: 'Nagaraj G S', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '10 Years', description: 'His firm belief in the role of physics in unlocking the secrets of the cosmos is the driving force behind his dedicated teaching.', photo: p108 },
    { id: 109, name: 'Seema K R', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '11 Years', description: 'Her expertise in simplifying complex physics topics is a testament to her unique approach.', photo: p109 },
    { id: 110, name: 'Sachin P R', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '05 Years', description: 'His students admire his ability to make abstract physics theories relevant and engaging.', photo: p110 },
    { id: 111, name: 'Anupama B', qualification: 'M.Sc.', achievements: '', experience: '07 Years', description: 'Her commitment to instilling a passion for physics is evident in the enthusiasm of her students.', photo: p111 },
    { id: 112, name: 'Sahana S G', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '03 Years', description: 'Her commitment to instilling a passion for physics is evident in the enthusiasm of her students.', photo: p112 },
    { id: 113, name: 'Maruthi ', qualification: 'M.Sc. ', achievements: 'Cleared KSET ', experience: '05 Years', description: 'Her commitment to instilling a passion for physics is evident in the enthusiasm of her students.', photo: p113 },
  ],
  Maths: [
    { id: 201, name: 'Srilakshmi H K', qualification: 'M.Sc', achievements: '', experience: '18 years', description: 'Widely recognized for her knack in breaking down intricate mathematical ideas fuels commitment to inspiring and educating their students.', photo: m201 },
    { id: 202, name: 'Arjun K K', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '12 Years', description: 'He is distinguished by their skill in making challenging mathematical concepts understandable, and his dedication towards Education sets him apart.', photo: m202 },
    { id: 203, name: 'Rakshit Kumar S', qualification: 'M.Sc., B.Ed.', achievements: <a href='https://www.flipkart.com/nest-kcet-mathematics/p/itm2fc0c913b4f73' target='_blank' rel="noreferrer">Author of Karnataka’s No.1 <span className='font-bold'>NEST KCET MATHEMATICS</span>.<span className='text-blue-700 text-sm ml-2'>Click here</span></a> , experience: '12 Years', description: 'He stands out for their proficiency in simplifying complex mathematical theories, also his dedication to instilling a love for learning is deeply anchored in his teaching methods.', photo: m203 },
    { id: 204, name: 'Abhishek N', qualification: 'M.Sc., B.Ed.', achievements: <a href='https://www.amazon.in/NEST-MATHEMATICS-Rakshit-Kumar-Books/dp/9357863699' target='_blank' rel="noreferrer">Author of Karnataka’s No.1 <span className='font-bold'>NEST KCET MATHEMATICS</span>. <span className='text-blue-700 text-sm ml-2'>Click here</span></a>, experience: '10 Years', description: 'Known for his exceptional talent in making challenging mathematical concepts accessible. also, his passion for teaching and inspiring students sets him apart.', photo: m204 },
    { id: 205, name: 'Vikas H N', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '08 Years', description: 'Celebrated for his adeptness at simplifying complex mathematical concepts, also his dedication to nurturing a passion for learning is firmly grounded.', photo: m205 },
    { id: 206, name: 'Anoop Patil K N', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '06 Years', description: 'He possesses a remarkable talent for making complex mathematical concepts approachable, and his unyielding devotion to fostering a love for learning is non matchable.', photo: m206 },
    { id: 207, name: 'Gireesh B', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '06 Years', description: 'Highly respected for his ability to make difficult mathematical ideas accessible, his unwavering commitment to instilling a passion for learning is deeply manifested in his teaching methods', photo: m207 },
    { id: 208, name: 'Harish P R', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '12 Years', description: 'His unwavering devotion to fostering a love for learning is underpinned by their profound belief in the transformative potential of mathematics.', photo: m208 },
    // Add more lecturers
  ],
  Chemistry: [
    { id: 300, name: 'Prof. B.N. Vishwanathaiah', qualification: 'M.Sc.', achievements: '', experience: '52 Years', description: 'His exceptional talent in making intricate chemical theories understandable sets him apart.', photo: c300 },
    { id: 301, name: 'Keshava Murthy S G', qualification: 'M.Sc.', achievements: '', experience: '19 Years', description: 'His exceptional talent in making intricate chemical theories understandable sets him apart.', photo: c301 },
    { id: 302, name: 'Ramya T R', qualification: 'M.Sc.', achievements: '', experience: '15 Years', description: 'With unwavering dedication, she empowers students to appreciate the beauty and significance of chemistry.', photo: c302 },
    { id: 303, name: 'Aparna T V', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '12 Years', description: 'Her passion for educating students extends beyond class rooms.', photo: c303 },
    { id: 304, name: 'Sneha M S', qualification: 'M.Sc.', achievements: '', experience: '07 Years', description: 'Her approachable teaching style makes even the most daunting chemical equations comprehensible.', photo: c304 },
    { id: 305, name: 'Praveen Kumar R G', qualification: 'M.Sc.', achievements: '', experience: '17 Years', description: 'Esteemed for his contribution to the field, he is dedicated to producing the chemists of the future.', photo: c305 },
    { id: 306, name: 'Jnanashree M', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '13 Years', description: 'Her encouragement of students to be curious & to respect the natural world made her teaching distinct.', photo: c306 },
    { id: 307, name: 'Vasanth Shetty ', qualification: 'M.Sc., B.Ed.', achievements: '1st Rank in M.Sc. (Organic Chemistry) also Cleared NET, KSET.', experience: '09 Years', description: 'His ability to make chemistry come alive is a testament to his unique dynamic approach.', photo: c307 },
    { id: 308, name: 'Suchithra K R ', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '06 Years', description: 'Her engaging teaching method made her students to feel the potential of chemistry.', photo: c308 },
    { id: 309, name: 'Divya M Y', qualification: 'M.Sc.', achievements: '', experience: '01 Years', description: 'Her commitment to showing the practical applications of chemistry inspires his students to excel.', photo: c309 },
    { id: 310, name: 'Shreevidya H N', qualification: 'M.Sc.', achievements: '', experience: '10 Years', description: 'Her firm belief in chemistrys role in solving real-world problems fuels their passion for teaching.', photo: c310 },
    { id: 311, name: 'Abhishek P', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '07 Years', description: 'As a Chemistry Lecturer, he instil the idea that chemistry is the key to unlocking innovations and sustainable solutions.', photo: c311 },
    { id: 312, name: 'Mopuri Nagendra', qualification: 'M.Sc., B.Ed.', achievements: '', experience: '13 Years', description: 'As a Chemistry Lecturer, he instil the idea that chemistry is the key to unlocking innovations and sustainable solutions.', photo: c312 },
    // Add more lecturers
  ],
  Biology: [
    { id: 401, name: 'Chandrashekar B S', qualification: 'M.Sc. (Zoology), B.Ed.', achievements: 'Cleared K-SET and pursuing Ph.D.', experience: '13 Years', description: 'His commitment to showing the practical applications of biology inspires his students to excel.', photo: b401 },
    { id: 409, name: 'Venkatesh', qualification: 'M.Sc. (Zoology), B.Ed.', achievements: '', experience: '15 Years', description: 'His lectures are a testament to his profound belief in the transformative potential of biology.', photo: b409 },
    { id: 402, name: 'Divya Rao', qualification: 'M.Sc. (Botany), B.Ed.', achievements: 'Cleared KSET', experience: '13 Years', description: 'A strong communicator, empathic passionate about her subject and know how to bring it to life has made her stand apart. ', photo: b402 },
    { id: 403, name: 'Pradeepa S P', qualification: 'M.Sc. (Zoology), B.Ed.', achievements: '', experience: '09 Years', description: 'His belief in biologys role in understanding lifes processes fuels his passion for teaching.', photo: b403 },
    { id: 404, name: 'Niveditha Y C', qualification: 'M.Sc. (Botany)., B.Ed.', achievements: '', experience: '05 Years', description: 'Her exceptional ability to convey complex biological ideas sets her apart.', photo: b404 },
    { id: 405, name: 'Sunildutt Joshi', qualification: 'M.Sc. (Botany), B.Ed.', achievements: '', experience: '08 Years', description: 'His belief that biology is fundamental to understanding life is evident in his unwavering commitment to the subject.', photo: b405 },
    { id: 406, name: 'Amulya N S', qualification: 'M.Sc. (Botany), B.Ed.', achievements: 'Cleared KSET', experience: '07 Years', description: 'With unwavering dedication, she inspires students to appreciate the richness of the natural world.', photo: b406 },
    { id: 407, name: 'Shilpa ', qualification: 'M.Sc. (Botany), B.Ed.', achievements: '', experience: '14 Years', description: 'Her belief that biology is fundamental to understanding life is evident in her unwavering commitment to the subject.', photo: b407 },
    // { id: 408, name: 'Gururaj K', qualification: 'M.Sc. (Zoology)', achievements: '', experience: '02 Years', description: 'His firm belief that biology is key to exploring the mysteries of life shapes his dedicated teaching.', photo: b408 },
    { id: 410, name: 'Siddharth S Hegde', qualification: 'M.Sc. (Zoology)', achievements: 'Cleared KSET, CSIR-NET and GATE.', experience: '05 Years', description: 'He is celebrated for his talent in fostering a love for biology in students from all walks of life.', photo: b410 },
  ],
  Computers: [
    { id: 501, name: 'Prabhanjana G G', qualification: 'M.Sc.', achievements: '', experience: '11 Years', description: 'He is known for their innovation in teaching, making computer science concepts come alive for students.', photo: cs501 },
    { id: 502, name: 'Deepak Kumar K K', qualification: 'M.C.A', achievements: 'Qualified KSCA UMPIRE', experience: '18 Years', description: 'His remarkable ability to make complex coding concepts accessible and exciting for their students made him stand apart.', photo: cs502 },
    { id: 503, name: 'N Suvarna', qualification: 'M.Sc.', achievements: '', experience: '15 Years', description: 'Her students admire their ability to make abstract computer science theories relevant and engaging.', photo: cs503 },
  ],
  Languages: [],
  Others: [
    { id: 701, name: 'Babu Kumar M', qualification: 'M.Lib., M.A', achievements: '', experience: 'Librarian ', description: 'As our dedicated Librarian, he plays a pivotal role in curating and managing our extensive collection of knowledge resources.', photo: o701 },
    { id: 702, name: 'Harish Patil M G', qualification: 'B.A., M.P.Ed', achievements: 'Represented Kho-Kho National Team.', experience: 'Physical Education Teacher', description: 'As our dedicated Physical Director, he is instrumental in fostering a culture of health, fitness, and sportsmanship within our institution.', photo: o702 },
    { id: 703, name: 'Sanjay R', qualification: 'M.Sc.,PGD (HR).', achievements: '', experience: 'Students Welfare Officer', description: 'His expertise in addressing student concerns, providing guidance, and promoting mental and emotional health is invaluable to our academic community.', photo: o703 },
  ]

};

const Languages = {
  English: [
    { id: 601, name: 'Sushma', qualification: 'M.A, B.Ed.', achievements: '', experience: '19 Years', description: 'Her belief in the role of English in effective communication and literary appreciation is evident in her unwavering commitment to the subject', photo: l601 },
    { id: 602, name: 'Ganesh H R', qualification: 'M.A, B.Ed.', achievements: 'Cleared TET', experience: '10 Years', description: 'HIS belief in the importance of English in shaping our understanding of the world fuels HIS passion for teaching.', photo: l602 },
    { id: 603, name: 'Anitha Jacob', qualification: 'M.A, B.Ed.', achievements: '', experience: '06 Years', description: 'Esteemed for her contributions to the field, she is dedicated to producing the writers and communicators of the future.', photo: l603 },
  ],

  Kannada: [
    { id: 604, name: 'Basavaraju K R', qualification: 'M.A, B.Ed.', achievements: '', experience: '24 Years', description: 'His commitment to nurturing a new generation of Kannada speakers is driven by his belief in the transformative potential of our cultural heritage.', photo: l604 },
    { id: 605, name: 'Chandrappa K S', qualification: 'M.A, B.Ed.', achievements: 'Cleared NET, KSET and TET', experience: '20 Years', description: 'He empowers students to embrace the beauty of our language and traditions, driven by HIS deep passion for Kannada', photo: l605 },
  ],
  Hindi: [
    { id: 607, name: 'Nageshachari S P', qualification: 'M.A, B.Ed.', achievements: ' M.Phil.', experience: '22 Years', description: 'He stands out for his remarkable ability to convey the intricacies of Hindi with clarity and enthusiasm, fostering a deep understanding of the language.', photo: l607 }
  ],
  Sanskrit: [
    { id: 606, name: 'Dr.Mythreyi H L', qualification: 'M.A, B.Ed.', achievements: ' M.Phil.,Ph.D.', experience: '18 Years', description: 'Her dedication to promoting Sanskrit language and culture extends beyond the classroom, enriching our institution.', photo: l606 },
  ]
}

function Faculties() {
  const [activeTab, setActiveTab] = useState(departments[0]);
  const [languagesTab, setLanguagesTab] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (activeTab === 'Languages') {
      setLanguagesTab(true);
    } else {
      setLanguagesTab(false);
    }
  }, [activeTab])

  return (
    <div className="container mx-auto px-4 py-2">
      <div className='bg-white w-full text-center'>
        <h1 className="text-3xl font-bold my-4" data-aos="fade-down" data-aos-duration="1500">Faculty Page</h1>
        <div className="flex my-4 justify-center flex-wrap" data-aos="fade-right" data-aos-duration="1500">
          {departments.map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`${tab === activeTab
                ? 'bg-orange-500 text-white'
                : 'bg-gray-300 text-gray-700'
                } px-4 py-2 mr-2 mt-2 rounded-md`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {!languagesTab && <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-10">
        {facultyData[activeTab].map((lecturer) => (
          <div key={lecturer.id} data-aos="fade-up" data-aos-duration="1500" className="bg-orange-200 border p-4 rounded-lg shadow-md text-center">
            <img
              src={lecturer.photo} // Add the photo source
              alt={lecturer.name}
              className="w-36 h-36 rounded-full mb-2 mx-auto object-fit"
            />
            <h2 className="text-xl font-semibold mt-2">{lecturer.name}</h2>
            <p className="text-gray-500">{lecturer.qualification}</p>
            <p className="text-gray-500">{lecturer.achievements}</p>
            {(activeTab !== 'Others') && <p className="mt-2">Experience: <span className="text-gray-500">{lecturer.experience}</span> </p>}
              {(activeTab === 'Others') && <p className="mt-2">Designation: <span className="text-gray-500">{lecturer.experience}</span> </p>}
            {/* <p className="mt-2">{lecturer.description}</p> */}
          </div>
        ))}
      </div>}

      {languagesTab && <div>
        {/* <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 mx-auto">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
            Select Language
          </label>
          <div class="relative">
            <select value={selectedLanguage} onChange={changeSelectedLanguage} class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
              <option value="English">English</option>
              <option value="Kannada">Kannada</option>
              <option value="Hindi">Hindi</option>
              <option value="Sanskrit">Sanskrit</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
            </div>
          </div>
        </div> */}
        <div className="flex my-4 justify-center flex-wrap" data-aos="fade-right" data-aos-duration="1500">
          <div className='border-2 p-2'>
            {LanguagesOptions.map((tab) => (
              <button
                key={tab}
                onClick={() => setSelectedLanguage(tab)}
                className={`${tab === selectedLanguage
                  ? 'bg-blue-500 text-white'
                  : 'bg-white text-blue-500'
                  } px-4 py-2 mr-2 rounded-md`}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-10">
          {Languages[selectedLanguage].map((lecturer) => (
            <div key={lecturer.id} data-aos="fade-up" data-aos-duration="1500" className="bg-orange-200 border p-4 rounded-lg shadow-md text-center">
              <img
                src={lecturer.photo} // Add the photo source
                alt={lecturer.name}
                className="w-36 h-36 rounded-full mb-2 mx-auto"
              />
              <h2 className="text-xl font-semibold mt-2">{lecturer.name}</h2>
              <p className="text-gray-500">{lecturer.qualification}</p>
              <p className="text-gray-500">{lecturer.achievements}</p>
              <p className="mt-2">Experience: <span className="text-gray-500">{lecturer.experience}</span> </p>
              {/* <p className="mt-2">{lecturer.description}</p> */}
            </div>
          ))}
        </div>
      </div>}
    </div>
  );
}

export default Faculties;
