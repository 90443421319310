import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css';

const Contact = () => {

    const [emailSendStatus, setEmailSendStatus] = useState('');

    const form = useRef();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(emailSendStatus === 'success' || emailSendStatus === 'error'){
            setTimeout(() => {
                setEmailSendStatus('')
            }, 4000);
        }
    },[emailSendStatus])

    const sendQuery = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_dws02cg', 'template_wsdvcn7', form.current, 'wVvAknBe233W2N47u')
            .then((result) => {
                console.log(result.text);
                setEmailSendStatus('success');
                e.target.reset();
            }, (error) => {
                setEmailSendStatus('error');
                console.log(error.text);
            });

            window.scrollTo(0, 0);

    };

    return (
        <div className='w-[95%] md:w-[80%] mx-auto'>
            {(emailSendStatus === 'success') && <div role="alert" className="rounded border-s-4 border-green-500 p-4">
                <strong className="block font-medium text-green-500"> Email received Successfully , We will get back to you soon. </strong>
            </div>}
            {(emailSendStatus === 'error') && <div role="alert" className="rounded border-s-4 border-red-500 p-4">
                <strong className="block font-medium text-red-800"> Something went wrong, try again after some time </strong>
            </div>}
            <section>
                <div class="container px-6 py-12 mx-auto">
                    <div class="lg:flex lg:items-center lg:-mx-6">
                        <div class="lg:w-1/2 lg:mx-6 backgroundImage">
                            <h1 class="text-2xl font-semibold text-gray-800 capitalize dark:text-white lg:text-3xl">
                                Contact us for more info
                            </h1>

                            <div class="mt-6 space-y-8 md:mt-8">
                                <a href='https://maps.app.goo.gl/RRvy1TSqVjkMszAJ7' target='_blank' rel="noreferrer" class="flex items-start -mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>

                                    <span class="mx-2 text-gray-700 w-72 dark:text-gray-400">
                                        PACE PU College, <br></br>
                                        Shivamogga-Harihar Road,<br></br>
                                        Tavarachatnalli, Shivamogga,<br></br>
                                        Karnataka-577216.
                                    </span>
                                </a>
                                <a href="tel:+918951921555" class="flex items-start -mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path st roke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>

                                    <p class="mx-2 text-gray-700 w-72 dark:text-gray-400">+91 89519 21555</p>
                                </a>
                                <a href="tel:+918951821555" class="flex items-start -mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        {/* <path st    roke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /> */}
                                    </svg>

                                    <p class="mx-2 text-gray-700 w-72 dark:text-gray-400">+91 89518 21555</p>
                                </a>
                                <a href="tel:+8951721555" class="flex items-start -mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        {/* <path st    roke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /> */}
                                    </svg>

                                    <p class="mx-2 text-gray-700 w-72 dark:text-gray-400">+91 89517 21555</p>
                                </a>

                                <a href="tel:+919448884903" class="flex items-start -mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        {/* <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /> */}
                                    </svg>

                                    <p class="mx-2 text-gray-700 w-72 dark:text-gray-400">+91 94488 84903</p>
                                </a>
                                <a href="tel:+919900148983" class="flex items-start -mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        {/* <path stroke-linecap="round" stroke-linejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /> */}
                                    </svg>

                                    <p class="mx-2 text-gray-700 w-72 dark:text-gray-400">+91 99001 48983</p>
                                </a>

                                <a href="mailto:admissions@pacepuc.com" class="flex items-start -mx-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>

                                    <span class="mx-2 text-gray-700 truncate w-72 dark:text-gray-400">admissions@pacepuc.com</span>
                                </a>
                            </div>
                        </div>

                        <div class="mt-8 lg:w-1/2 lg:mx-6">
                            <div
                                class="w-full px-8 py-10 mx-auto overflow-hidden bg-orange-300 rounded-lg shadow-2xl dark:bg-gray-900 lg:max-w-xl shadow-gray-300/50 dark:shadow-black/50">
                                <h1 class="text-lg font-medium text-gray-700">Provide us your contact details.</h1>

                                <form ref={form} class="mt-6" onSubmit={sendQuery}>
                                    <div class="flex-1">
                                        <label class="block mb-2 text-sm text-black dark:text-gray-200">Full Name</label>
                                        <input required type="text" placeholder="Enter full name"
                                            name="user_name" class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div class="flex-1 mt-6">
                                        <label class="block mb-2 text-sm text-black dark:text-gray-200">Contact Number</label>
                                        <input required type="tel" placeholder="+91 XXXXX XXXXX" name="user_telephone" class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div class="flex-1 mt-6">
                                        <label class="block mb-2 text-sm text-black dark:text-gray-200">Email Id</label>
                                        <input required type="email" placeholder="abcxxxx@abc.com" name="user_email" class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div class="flex-1 mt-6">
                                        <label class="block mb-2 text-sm text-black dark:text-gray-200">Current School Name</label>
                                        <input required type="text" placeholder="Enter school name" name="user_schoolName" class="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div class="w-full mt-6">
                                        <label class="block mb-2 text-sm text-black dark:text-gray-200">Ask us your query :</label>
                                        <textarea rows="3" class="block w-full h-16 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-48 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" placeholder="Ask your questions" name="user_message"></textarea>
                                    </div>

                                    <button type='submit' class="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-orange-500 rounded-md hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                        Submit Details
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact