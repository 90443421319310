import React, { useState, useEffect } from 'react';
import j101 from '../images/achievements/jee/ajay.png'
import j102 from '../images/achievements/jee/chinmay.png'
import j103 from '../images/achievements/jee/deepa.png'
import j104 from '../images/achievements/jee/khushi.png'
import j105 from '../images/achievements/jee/viren1.png'
import j106 from '../images/achievements/jee/samarth.png'
import j107 from '../images/achievements/jee/viren2.png'
import j108 from '../images/achievements/jee/Ruthvik.png'
import j109 from '../images/achievements/jee/Prajana.png'
import j110 from '../images/achievements/jee/Anjana.png'
import j111 from '../images/achievements/jee/Aakanksha.png'
import j112 from '../images/achievements/jee/Prajwal.png'
import j113 from '../images/achievements/jee/Sudhurshana.png'

import n201 from '../images/achievements/neet/samanwitha.png'
import n202 from '../images/achievements/neet/deepa.png'
import n203 from '../images/achievements/neet/ananya.png'
import n204 from '../images/achievements/neet/pratham.png'
import n205 from '../images/achievements/neet/saniya.png'
import n206 from '../images/achievements/neet/Sridhar.png'
import n207 from '../images/achievements/neet/Sameer.png'
import n208 from '../images/achievements/neet/PrathamKG.png'
import n209 from '../images/achievements/neet/Hamsini.png'
import n210 from '../images/achievements/neet/Dhruva.png'
import n211 from '../images/achievements/neet/Pavan.png'
import n212 from '../images/achievements/neet/syed.png'

import c301 from '../images/achievements/jee/ajay.png'
import c302 from '../images/achievements/jee/chinmay.png'
import c303 from '../images/achievements/jee/deepa.png'
import c304 from '../images/achievements/cet/kantharaj.png'
import c305 from '../images/achievements/neet/samanwitha.png'
import c306 from '../images/achievements/jee/viren2.png'
import c307 from '../images/achievements/cet/Prajwal.png'
import c310 from '../images/achievements/cet/Vibha.png'

import b401 from '../images/achievements/boards/maadhyam.png'
import b402 from '../images/achievements/boards/pranathi.png'
import b403 from '../images/achievements/boards/chinmay.png'
import b404 from '../images/achievements/boards/lavanya.png'
import b406 from '../images/achievements/boards/Lekhana.png'
import b407 from '../images/achievements/boards/Surabhi.png'
import b408 from '../images/achievements/boards/Prathamesh.png'
import b409 from '../images/achievements/boards/Sumana.png'

import s501 from '../images/achievements/sports/spoorthi g.jpg'
import s502 from '../images/achievements/sports/ankitha pawar.jpg'
import s503 from '../images/achievements/sports/sanaka.jpg'
import s504 from '../images/achievements/sports/akash n.jpg'
import s505 from '../images/achievements/sports/harshitha patil.jpg'
import s506 from '../images/achievements/sports/stuti hittur.jpg'
import s507 from '../images/achievements/sports/GOURAV SHETTY.jpg'
import s508 from '../images/achievements/sports/gnanendra.jpg'
import s509 from '../images/achievements/sports/keerthi ramesh.jpg'
import s510 from '../images/achievements/sports/moola greeshma.jpg'
import s511 from '../images/achievements/sports/rithesh.jpg'
import s512 from '../images/achievements/sports/manoj.jpg'




const groups = ['JEE', 'NEET', 'KCET', 'Boards', 'Sports'];

const achievementsData = {
    JEE: [
        { id: 108, name: 'RUTHVIK CHETHAN', data: 'JEE-ADVANCED AIR 68', data2: 'Pursuing CSE in IIT-Dharwad', photo: j108 },
        { id: 109, name: 'PRAJANA S', data: 'JEE-ADVANCED AIR 643', data2: 'Pursuing CSE in IIT-Dharwad', photo: j109 },
        { id: 101, name: 'CHINMAYA P', data: 'Percentile: 99.49', photo: j102 },
        { id: 102, name: 'AJAY M N', data: 'Percentile: 98.33', photo: j101 },
        { id: 103, name: 'VIREN RANJAN MUNDRE', data: 'Percentile: 97.9', photo: j107 },
        { id: 107, name: 'KOUSHIK H S', data: 'Percentile: 97.67', photo: j105 },
        { id: 104, name: 'KHUSHI H', data: 'Percentile: 96.41', photo: j104 },
        { id: 303, name: 'SAMANVITHA R', data: 'Percentile: 96.07', photo: c305 },
        { id: 110, name: 'ANJANA T K', data: 'Percentile: 96', photo: j110 },
        { id: 111, name: 'AAKANKSHA V R', data: 'Percentile: 96', photo: j111 },
        { id: 112, name: 'PRAJWAL A CHATRA ', data: 'Percentile: 95.98', photo: j112 },
        { id: 113, name: 'SUDHARSHANA V SHARMA ', data: 'Percentile: 95.21', photo: j113 },
        { id: 105, name: 'DEEPA P BELALADAVAR', data: 'Percentile: 95.15', photo: j103 },
        { id: 106, name: 'SAMARTH R PATIL', data: 'Percentile: 95.06', photo: j106 },
    ],
    NEET: [
        { id: 206, name: 'SRIDHAR M', data: 'Score : 629 / 720', photo: n206 },
        { id: 201, name: 'SAMANVITHA R', data: 'Score : 620 / 720', photo: n201 },
        { id: 202, name: 'DEEPA P BELALADAVAR', data: 'Score : 616 / 720', photo: n202 },
        { id: 207, name: 'SAMEER KULKARNI', data: 'Score : 608 / 720', photo: n207 },
        { id: 208, name: 'PRAMATHA K G ', data: 'Score : 586 / 720', photo: n208 },
        { id: 209, name: 'HAMSINI D S ', data: 'Score : 584 / 720', photo: n209 },
        { id: 210, name: 'DHRUVA S ', data: 'Score : 557 / 720', photo: n210 },
        { id: 211, name: 'PAVAN S ', data: 'Score : 556 / 720', photo: n211 },
        { id: 203, name: 'ANANYA G J', data: 'Score : 555 / 720', photo: n203 },
        { id: 204, name: 'PRATHAM GOWDA', data: 'Score : 546 / 720', photo: n204 },
        { id: 212, name: 'SYED ADIL ', data: 'Score : 530 / 720', photo: n212 },
        { id: 205, name: 'SANIYA FIRDOSE', data: 'Score : 516 / 720', photo: n205 },
    ],
    KCET: [
        { id: 301, name: 'AJAY M N', data: 'B.Sc(Ag) :252', data2: 'Engineering :350', photo: c301 },
        { id: 302, name: 'CHINMAYA P', data: 'B.Sc(Ag) :204', data2: 'Engineering :512', photo: c302 },
        { id: 303, name: 'SAMANVITHA R', data: 'B.Sc(Ag) :329 Engineering :1130', data2: 'Veterinary Science :581', photo: c305 },
        { id: 304, name: 'DEEPA P BELALADAVAR', data: 'BNYS :446, B.Sc(Ag) :488', data2: 'Veterinary Science :683', photo: c303 },
        { id: 305, name: 'VIREN RANJAN MUNDRE', data: 'Engineering :607', data2: '', photo: c306 },
        { id: 306, name: 'KANTHRAJ D R', data: 'Veternary(Prac) :40', data2: 'B.Sc(Ag)(Prac) :47', photo: c304 },
        { id: 307, name: 'PRAJWAL A CHATRA ', data: 'Engineering :250 ', data2: 'B.PHARMA :680', photo: c307 },
        { id: 308, name: 'DHRUVA S', data: 'B.SC(Ag) :181, Veterinary science :359', data2: ' Naturopathy :195, Engineering :795', photo: n210 },
        { id: 309, name: 'AAKANKSHA V R', data: 'Engineering- 558', data2: '', photo: j111 },
        { id: 310, name: 'VIBHA VIJAYKUMAR', data: 'B.SC(Ag) :436', data2: 'Engineering :913', photo: c310 },
    ],
    Boards: [
        { id: 405, name: 'DHRUVA S', data: 'Marks: 593 / 600 (6th RANK IN STATE)', photo: n210 },
        { id: 406, name: 'LEKHANA R', data: 'Marks: 590 / 600 (9th RANK IN STATE)', photo: b406 },
        { id: 407, name: 'SURABHI NADIG', data: 'Marks: 590 / 600 (9th RANK IN STATE)', photo: b407 },
        { id: 408, name: 'PRATHAMESH V', data: 'Marks: 589 / 600 (10th RANK IN STATE)', photo: b408 },
        { id: 409, name: 'SUMANA BHAVE', data: 'Marks: 589 / 600 (10th RANK IN STATE)', photo: b409 },
        { id: 401, name: 'MAADHYAM M SWAMY', data: 'Marks: 588 / 600', photo: b401 },
        { id: 402, name: 'PRANATHI N G', data: 'Marks: 583 / 600', photo: b402 },
        { id: 403, name: 'CHINMAYA P', data: 'Marks: 582 / 600', photo: b403 },
        { id: 404, name: 'LAVANYA S M', data: 'Marks: 582 / 600', photo: b404 },

    ],
    Sports: [
        { id: 501, name: 'SPOORTHI G KUDTARKAR', data: 'I PUC', data2: 'KARATE , State Level', photo: s501 },
        { id: 502, name: 'ANKITHA B PAWAR', data: 'I PUC', data2: 'KARATE , State Level', photo: s502 },
        { id: 503, name: 'K N SANAKA', data: 'I PUC', data2: 'CHESS , State Level', photo: s503 },
        { id: 504, name: 'AKASH N', data: 'I PUC', data2: 'CHESS , State Level', photo: s504 },
        { id: 505, name: 'HARSHITHA PATIL M V', data: 'II PUC', data2: 'KARATE , State Level', photo: s505 },
        { id: 506, name: 'STUTI HITTUR', data: 'II PUC', data2: 'SWIMMING , State Level', photo: s506 },
        { id: 507, name: 'GAURAV R SHETTY', data: 'II PUC', data2: 'FOOT BALL , State Level', photo: s507 },
        { id: 508, name: 'GNANENDRA D', data: 'II PUC', data2: 'HANDBALL , State Level', photo: s508 },
        { id: 509, name: 'KEERTHI RAMESH', data: 'II PUC', data2: 'CHESS , State Level', photo: s509 },
        { id: 510, name: 'MOOLA GREESHMA', data: 'II PUC', data2: 'CHESS , National Level', photo: s510 },
        { id: 511, name: 'RITHESH RAJ M L', data: 'II PUC', data2: 'CHESS , State Level', photo: s511 },
        { id: 512, name: 'MANOJ K M', data: 'II PUC', data2: 'TAEKWONDO , State Level', photo: s512 },

    ]
};



const Achievements = () => {
    const [activeTab, setActiveTab] = useState(groups[0]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='mt-[40px]'>
            <section class="bg-white dark:bg-gray-900">
                <div class="container px-6 py-6 mx-auto">
                    <h1 class="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white" data-aos="fade-down" data-aos-duration="1500">our Students Achievements</h1>

                    <p class="max-w-2xl mx-auto my-4 text-center text-gray-500 dark:text-gray-300" data-aos="fade-down" data-aos-duration="1500">
                        So far, PACE has produced 500+ future doctors, 2500+ future engineers and has also contributed thousands of workaholic and sincere future citizens to this great country.
                        <p className='font-bold'>Some glimpses of recent achievements by our students</p>
                    </p>

                    <div class="flex items-center justify-center" data-aos="fade-right" data-aos-duration="1500">
                        <div class="flex items-center p-1 border border-orange-500 dark:border-orange-400 rounded-xl overflow-x-auto">
                            {groups.map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`${tab === activeTab ? 'text-white bg-orange-500' : 'text-orange-500 hover:bg-orange-500 hover:text-white'} px-4 py-2 text-sm font-medium  capitalize  md:py-3 rounded-xl md:px-12`}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>

                    {(activeTab === 'KCET') && <div className='text-center mt-4'>
                        <p className='text-2xl font-bold mt-2'>State Ranks</p>
                    </div>}
                    {(activeTab === 'JEE') && <div className='text-center mt-4'>
                        <p className='text-2xl font-bold mt-2'>35 STUDENTS OUT OF 52 QUALIFIED FOR JEE ADVANCED 2023.</p>
                    </div>}
                    <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-3 xl:grid-cols-4">
                        {achievementsData[activeTab].map((student) => (
                            <div key={student.id} className="flex flex-col items-center" data-aos="fade-up" data-aos-duration="1500">
                                <img class="object-fill w-full rounded-xl aspect-square" src={student.photo} alt="Student" />
                                <h1 class="mt-4 text-xl font-semibold text-gray-700 capitalize dark:text-white">{student.name}</h1>
                                <p class="mt-2 text-gray-500 capitalize dark:text-gray-300">{student.data}</p>
                                <p class="mt-2 text-gray-500 capitalize dark:text-gray-300">{student.data2}</p>
                            </div>
                        ))}

                        {/* 
                        <div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                            <div class="flex flex-col items-center pb-10 pt-6">
                                <img class="w-36 h-36 mb-3 rounded-full shadow-lg" src={student.photo} />
                                <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{student.name}</h5>
                                <span class="text-sm text-gray-500 dark:text-gray-400">M.Sc.</span>
                                <span class="text-sm text-gray-500 dark:text-gray-400">Experience: 19 Years</span>
                                <span class="text-sm text-gray-500 dark:text-gray-400">Finished Ph.d</span>
                                <p class="p-2 text-center dark:text-gray-300">Esteemed for HIS contributions to the field, HE is dedicated to nurturing the physicists of tomorrow.</p>
                            </div>
                        </div> */}

                        {/* <div className="relative overflow-hidden rounded-lg">
                            <img src={j101} alt="" className="w-full" />
                            <div className="absolute bottom-5 left-0 w-full text-center">
                                <div className="relative mx-5 overflow-hidden rounded-lg bg-white px-3 py-2 dark:bg-dark-2">
                                    <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">{student.name}</h1>
                                    <p class=" text-gray-500 capitalize dark:text-gray-300">{student.data}</p>
                                    <p class=" text-gray-500 capitalize dark:text-gray-300">{student.data2}</p>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Achievements