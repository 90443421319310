import React, {useEffect} from 'react';
import library from '../images/Gallery/8.jpg';
import auditoriam from '../images/facilities/auditoriam.png';
import avClass from '../images/Gallery/DSC_PACE (21).jpeg';
import hostel from '../images/facilities/hostel.png';
import transportation from '../images/Gallery/DSC_PACE (48).jpeg';
import lab from '../images/Gallery/5.jpg';

const Facilities = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div>
      <section className="dark:bg-gray-800 dark:text-gray-100">
        <div className="container max-w-xl p-6 py-10 mx-auto space-y-12 lg:px-8 lg:max-w-7xl text-center">
          <div data-aos="fade-down" data-aos-duration="1500">
            <h2 className="text-2xl font-bold tracki text-center sm:text-3xl dark:text-gray-50">FACILITIES</h2>
          </div>
          <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
            <div data-aos="fade-up" data-aos-duration="1500">
              <h3 className="text-xl font-bold tracki sm:text-2xl dark:text-gray-50">LIBRARY</h3>
              <p className="mt-3 text-lg dark:text-gray-400">PACE PU College supports its students' academic endeavours through a well-equipped library with more than 9000 books, focusing on JEE Mains-Advance, NEET and KCET along with a selection of board-level textbooks. To keep students updated, the library provides newspapers in Kannada and English, offering a broad spectrum of news. Students explore various monthly magazines to enhance their knowledge and stay updated of developments in the outer world. The commitment to a conducive learning environment is evident in a separate reading hall with a 250-seat capacity, fostering a focused study atmosphere. The college offers a digital library and internet facilities, ensuring access to online resources for an enriched learning experience. To meet departmental needs, there is a specialized library, tailoring resources to specific academic areas, enhancing the learning experience. An open access policy underscores the college's commitment to an inclusive learning environment, removing restrictions on resource access.</p>
            </div>
            <div aria-hidden="true" className="mt-10 lg:mt-0" data-aos="fade-up" data-aos-duration="1500">
              <img className='mx-auto' width="100%" alt='library' src={library} />
            </div>
          </div>
          <div>
            <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
              <div className="lg:col-start-2" data-aos="fade-up" data-aos-duration="1500">
                <h3 className="text-xl font-bold tracki sm:text-2xl dark:text-gray-50">HOSTEL</h3>
                <p className="mt-3 text-lg dark:text-gray-400">The hostel facility comprises fully equipped blocks, with separate accommodations for Girl's and Boy's. Both hostels are situated in lush green surroundings, offering a delightful view from all rooms. In this phase, the Girls and Boys Hostel can accommodate approximately 500 students, providing shared spaces for study/library and an indoor play area. Each floor also includes a caretaker's room and 24*7 watchmen ensure campus security. For added security, students must present an outing pass to leave the campus. After classes, students gather in the classroom for study purposes, where subject lectures addressess any doubts students may have until 8:30 PM. Each student is expected to follow this protocol. Every day, students are served delicious and hygienic South Indian food, ensuring their nutritional needs are met.</p>
              </div>
              <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1" data-aos="fade-up" data-aos-duration="1500">
                <img className='mx-auto' width="100%" alt='hostel' src={hostel} />
              </div>
            </div>
          </div>
          <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
            <div data-aos="fade-up" data-aos-duration="1500">
              <h3 className="text-xl font-bold tracki sm:text-2xl dark:text-gray-50">LABORATORY</h3>
              <p className="mt-3 text-lg dark:text-gray-400">Students who choose science should have the opportunity to experience the magic of science firsthand through practical experiments in the laboratory. The quality of a science college is evident in the standard of its laboratories. At PACE Physics, Chemistry, Biology and Computer Science laboratories are  well-equipped and well-designed. Each student is provided with sufficient time and resources to conduct experiments. The laboratory staff offers individual attention, and faculty members are consistently available for guidance. What sets our labs apart is their uncrowded nature, allowing us to concentrate on the performance of each student.</p>
            </div>
            <div aria-hidden="true" className="mt-10 lg:mt-0" data-aos="fade-up" data-aos-duration="1500">
              <img className='mx-auto' width="100%" alt='laboratary' src={lab} />
            </div>
          </div>
          <div>
            <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
              <div className="lg:col-start-2" data-aos="fade-up" data-aos-duration="1500">
                <h3 className="text-xl font-bold tracki sm:text-2xl dark:text-gray-50">TRANSPORTATION</h3>
                <p className="mt-3 text-lg dark:text-gray-400">We take pride in our college's commitment to providing a reliable and efficient transportation system for our students and faculty. Our well-maintained fleet of buses is equipped with a state-of-the-art tracking system and CCTV surveillance, ensuring the safety and security of all students. For the convenience of our students to reach before defined time, we offer transportation services with buses running in from various locations like Bhadravathi, Honnali, Holehonnuru, B.R.Project and within Shivamogga city. We place a strong emphasis on maintaining discipline during bus journeys. This commitment is integral to ensuring the safety and well-being of all Students. Experience comfort, security and a sense of community with our college transportation services. Join us on a journey where every commute is as smooth as your academic and professional pursuits.</p>
              </div>
              <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1" data-aos="fade-up" data-aos-duration="1500">
                <img className='mx-auto' width="100%" alt='transportation' src={transportation} />
              </div>
            </div>
          </div>
          <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
            <div data-aos="fade-up" data-aos-duration="1500">
              <h3 className="text-xl font-bold tracki sm:text-2xl dark:text-gray-50">AUDITORIUM</h3>
              <p className="mt-3 text-lg dark:text-gray-400">Our college auditorium stands as a pinnacle of modern amenities, boasting a sophisticated centralized air conditioning system that ensures a comfortable and conducive environment for up to 1500 attendees. The state-of-the-art HVAC system guarantees optimal temperature control, enhancing the overall experience of events held within its walls. This technological marvel is complemented by a high-quality audio system, delivering crystal-clear sound throughout the vast space. With 1500 seats meticulously arranged to offer unobstructed views of the stage, the auditorium provides a spacious and inviting atmosphere for students and guests alike. The combination of centralized AC and top-notch audio facilities creates an immersive and enjoyable setting, making it an ideal venue for lectures, presentations, performances, and various events. Our commitment to providing cutting-edge infrastructure underscores our dedication to fostering an enriching educational and cultural environment within the college.</p>
            </div>
            <div aria-hidden="true" className="mt-10 lg:mt-0" data-aos="fade-up" data-aos-duration="1500">
              <img className='mx-auto' width="100%" alt='auditoriam' src={auditoriam} />
            </div>
          </div>
          <div>
            <div className="grid lg:gap-8 lg:grid-cols-2 lg:items-center">
              <div className="lg:col-start-2" data-aos="fade-up" data-aos-duration="1500">
                <h3 className="text-xl font-bold tracki sm:text-2xl dark:text-gray-50">AV CLASS ROOMS</h3>
                <p className="mt-3 text-lg dark:text-gray-400">Our PACE PU College AV classrooms stand at the forefront of innovative education, seamlessly integrating present-day technology to enhance students' understanding. With state-of-the-art audio-visual systems, these classrooms transform traditional learning into an immersive and dynamic experience. High-definition projectors coupled with interactive whiteboards provide a platform for engaging visual presentations, enabling educators to convey complex concepts with clarity. The integration of modern technology doesn't just stop at visuals it also ensure that every nuance of lectures and discussions is crystal clear, fostering a deeper comprehension. These tech-infused classrooms create an interactive learning environment, encouraging student participation through real-time collaborations and multimedia content. By embracing cutting-edge technology, our AV classrooms at PACE PU College redefine the educational landscape, fostering a rich and stimulating atmosphere for students to thrive in their academic pursuits.</p>
              </div>
              <div className="mt-10 lg:mt-0 lg:col-start-1 lg:row-start-1" data-aos="fade-up" data-aos-duration="1500">
                <img className='mx-auto' width="100%" alt='classroom' src={avClass} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Facilities