// Gallery.js

import React from 'react';
// import pic1 from '../images/Gallery/SCP_4173.JPG'
// import pic2 from '../images/Gallery/SCP_4179.JPG'
// import pic3 from '../images/Gallery/SCP_4183.JPG'
// import pic4 from '../images/Gallery/SCP_4227.JPG'
// import pic5 from '../images/Gallery/SCP_4232.JPG'
// import pic6 from '../images/Gallery/SCP_4267.JPG'
// import pic7 from '../images/Gallery/SCP_4292.JPG'
// import pic8 from '../images/Gallery/SCP_4303.JPG'
// import pic9 from '../images/Gallery/SCP_4310.JPG'
// import pic10 from '../images/Gallery/SCP_4337.JPG'
// import pic11 from '../images/Gallery/SCP_4347.JPG'
// import pic12 from '../images/Gallery/SCP_4348.JPG'
// import pic13 from '../images/Gallery/SCP_4364.JPG'
// import pic14 from '../images/Gallery/SCP_4367.JPG'
// import pic15 from '../images/Gallery/SCP_4388.JPG'
// import pic16 from '../images/Gallery/SCP_4400.JPG'
// import pic17 from '../images/Gallery/SCP_4405.JPG'
// import pic18 from '../images/Gallery/SCP_4408.JPG'
// import pic19 from '../images/Gallery/SCP_4430.JPG'
// import pic20 from '../images/Gallery/SCP_4517.JPG'
// import pic21 from '../images/Gallery/SCP_4523.JPG'
// import pic22 from '../images/Gallery/SCP_4537.JPG'
// import pic23 from '../images/Gallery/SCP_4541.JPG'
// import pic24 from '../images/Gallery/SCP_4567.JPG'
// import pic25 from '../images/Gallery/SCP_4607.JPG'
// import pic26 from '../images/Gallery/SCP_4610.JPG'
// import pic27 from '../images/Gallery/SCP_4614.JPG'
// import pic28 from '../images/Gallery/SCP_4635.JPG'
// import pic29 from '../images/Gallery/SCP_4638.JPG'
// import pic30 from '../images/Gallery/SCP_4650.JPG'
// import pic31 from '../images/Gallery/SCP_4663.JPG'
// import pic32 from '../images/Gallery/SCP_4666.JPG'
// import pic33 from '../images/Gallery/SCP_4676.JPG'
// import pic34 from '../images/Gallery/SCP_4680.JPG'
// import pic35 from '../images/Gallery/SCP_4699.JPG'
// import pic36 from '../images/Gallery/SCP_4705.JPG'
// import pic37 from '../images/Gallery/SCP_4707.JPG'
// import pic38 from '../images/Gallery/SCP_4718.JPG'
// import pic39 from '../images/Gallery/SCP_4720.JPG'
// import pic40 from '../images/Gallery/SCP_4723.JPG'
// import pic41 from '../images/Gallery/SCP_4726.JPG'
// import pic42 from '../images/Gallery/SCP_4730.JPG'
// import pic43 from '../images/Gallery/SCP_4731.JPG'
// import pic44 from '../images/Gallery/SCP_4736.JPG'
// import pic45 from '../images/Gallery/SCP_4745.JPG'
// import pic46 from '../images/Gallery/SCP_4750.JPG'
// import pic47 from '../images/Gallery/SCP_4752.JPG'
// import pic48 from '../images/Gallery/SCP_4755.JPG'
// import pic49 from '../images/Gallery/SCP_4756.JPG'
// import pic50 from '../images/Gallery/SPN_7513.JPG'
// import pic51 from '../images/Gallery/SPN_7523.JPG'
// import pic52 from '../images/Gallery/SPN_7526.JPG'
// import pic53 from '../images/Gallery/SPN_7530.JPG'
// import pic54 from '../images/Gallery/SPN_7532.JPG'
// import pic55 from '../images/Gallery/SPN_7533.JPG'
// import pic56 from '../images/Gallery/SPN_7538.JPG'
// import pic57 from '../images/Gallery/SPN_7540.JPG'
// import pic58 from '../images/Gallery/SPN_7550.JPG'
// import pic59 from '../images/Gallery/SPN_7551.JPG'
// import pic60 from '../images/Gallery/SPN_7555.JPG'
// import pic61 from '../images/Gallery/SPN_7557.JPG'
// import pic62 from '../images/Gallery/SPN_7558.JPG'
// import pic63 from '../images/Gallery/SPN_7559.JPG'
// import pic64 from '../images/Gallery/SPN_7560.JPG'
// import pic65 from '../images/Gallery/SPN_7562.JPG'
// import pic66 from '../images/Gallery/SPN_7566.JPG'
// import pic67 from '../images/Gallery/SPN_7569.JPG'
// import pic68 from '../images/Gallery/SPN_7570.JPG'

// import Dpic1 from '../images/Gallery/DSC_PACE (1).jpeg';
// import Dpic2 from '../images/Gallery/DSC_PACE (2).jpeg';
// import Dpic3 from '../images/Gallery/DSC_PACE (3).jpeg';
// import Dpic4 from '../images/Gallery/DSC_PACE (4).jpeg';
// import Dpic5 from '../images/Gallery/DSC_PACE (5).jpeg';
// import Dpic6 from '../images/Gallery/DSC_PACE (6).jpeg';
// import Dpic7 from '../images/Gallery/DSC_PACE (7).jpeg';
// import Dpic8 from '../images/Gallery/DSC_PACE (8).jpeg';
// import Dpic9 from '../images/Gallery/DSC_PACE (9).jpeg';
// import Dpic10 from '../images/Gallery/DSC_PACE (10).jpeg';
// import Dpic11 from '../images/Gallery/DSC_PACE (11).jpeg';
// import Dpic12 from '../images/Gallery/DSC_PACE (12).jpeg';
// import Dpic13 from '../images/Gallery/DSC_PACE (13).jpeg';
// import Dpic14 from '../images/Gallery/DSC_PACE (14).jpeg';
// import Dpic15 from '../images/Gallery/DSC_PACE (15).jpeg';
// import Dpic16 from '../images/Gallery/DSC_PACE (16).jpeg';
// import Dpic17 from '../images/Gallery/DSC_PACE (17).jpeg';
// import Dpic18 from '../images/Gallery/DSC_PACE (18).jpeg';
// import Dpic19 from '../images/Gallery/DSC_PACE (19).jpeg';
// import Dpic20 from '../images/Gallery/DSC_PACE (20).jpeg';
// import Dpic21 from '../images/Gallery/DSC_PACE (21).jpeg';
// import Dpic22 from '../images/Gallery/DSC_PACE (22).jpeg';
// import Dpic23 from '../images/Gallery/DSC_PACE (23).jpeg';
// import Dpic24 from '../images/Gallery/DSC_PACE (24).jpeg';
// import Dpic25 from '../images/Gallery/DSC_PACE (25).jpeg';
// import Dpic26 from '../images/Gallery/DSC_PACE (26).jpeg';
// import Dpic27 from '../images/Gallery/DSC_PACE (27).jpeg';
// import Dpic28 from '../images/Gallery/DSC_PACE (28).jpeg';
// import Dpic29 from '../images/Gallery/DSC_PACE (29).jpeg';
// import Dpic30 from '../images/Gallery/DSC_PACE (30).jpeg';
// import Dpic31 from '../images/Gallery/DSC_PACE (31).jpeg';
// import Dpic32 from '../images/Gallery/DSC_PACE (32).jpeg';
// import Dpic33 from '../images/Gallery/DSC_PACE (33).jpeg';
// import Dpic34 from '../images/Gallery/DSC_PACE (34).jpeg';
// import Dpic35 from '../images/Gallery/DSC_PACE (35).jpeg';
// import Dpic36 from '../images/Gallery/DSC_PACE (36).jpeg';
// import Dpic37 from '../images/Gallery/DSC_PACE (37).jpeg';
// import Dpic38 from '../images/Gallery/DSC_PACE (38).jpeg';
// import Dpic39 from '../images/Gallery/DSC_PACE (39).jpeg';
// import Dpic40 from '../images/Gallery/DSC_PACE (40).jpeg';
// import Dpic41 from '../images/Gallery/DSC_PACE (41).jpeg';
// import Dpic42 from '../images/Gallery/DSC_PACE (42).jpeg';
// import Dpic43 from '../images/Gallery/DSC_PACE (43).jpeg';
// import Dpic44 from '../images/Gallery/DSC_PACE (44).jpeg';
// import Dpic45 from '../images/Gallery/DSC_PACE (45).jpeg';
// import Dpic46 from '../images/Gallery/DSC_PACE (46).jpeg';
// import Dpic47 from '../images/Gallery/DSC_PACE (47).jpeg';
// import Dpic48 from '../images/Gallery/DSC_PACE (48).jpeg';
// import Dpic49 from '../images/Gallery/DSC_PACE (49).jpeg';
// import Dpic50 from '../images/Gallery/DSC_PACE (50).jpeg';
// import Dpic51 from '../images/Gallery/DSC_PACE (51).jpeg';
// import Dpic52 from '../images/Gallery/DSC_PACE (52).jpeg';
// import Dpic53 from '../images/Gallery/DSC_PACE (53).jpeg';
// import Dpic54 from '../images/Gallery/DSC_PACE (54).jpeg';
// import Dpic55 from '../images/Gallery/DSC_PACE (55).jpeg';
// import Dpic56 from '../images/Gallery/DSC_PACE (56).jpeg';
// import Dpic57 from '../images/Gallery/DSC_PACE (57).jpeg';
// import Dpic58 from '../images/Gallery/DSC_PACE (58).jpeg';
// import Dpic59 from '../images/Gallery/DSC_PACE (59).jpeg';
// import Dpic60 from '../images/Gallery/DSC_PACE (60).jpeg';
// import Dpic61 from '../images/Gallery/DSC_PACE (61).jpeg';
// import Dpic62 from '../images/Gallery/DSC_PACE (62).jpeg';
// import Dpic63 from '../images/Gallery/DSC_PACE (63).jpeg';
// import Dpic64 from '../images/Gallery/DSC_PACE (64).jpeg';
// import Dpic65 from '../images/Gallery/DSC_PACE (65).jpeg';
// import Dpic66 from '../images/Gallery/DSC_PACE (66).jpeg';
// import Dpic67 from '../images/Gallery/DSC_PACE (67).jpeg';
// import Dpic68 from '../images/Gallery/DSC_PACE (68).jpeg';

import Dpic1 from '../images/Gallery/1.jpg';
import Dpic2 from '../images/Gallery/2.jpg';
import Dpic3 from '../images/Gallery/3.jpg';
import Dpic4 from '../images/Gallery/4.jpg';
import Dpic5 from '../images/Gallery/5.jpg';
import Dpic6 from '../images/Gallery/6.jpg';
import Dpic7 from '../images/Gallery/7.jpg';
import Dpic8 from '../images/Gallery/8.jpg';
import Dpic9 from '../images/Gallery/9.jpg';
import Dpic10 from '../images/Gallery/10.jpg';
import Dpic11 from '../images/Gallery/11.jpg';
import Dpic12 from '../images/Gallery/12.jpg';
import Dpic13 from '../images/Gallery/13.jpg';
import Dpic14 from '../images/Gallery/14.jpg';
import Dpic15 from '../images/Gallery/15.jpg';
import Dpic16 from '../images/Gallery/16.jpg';
import Dpic17 from '../images/Gallery/17.jpg';
import Dpic18 from '../images/Gallery/18.jpg';

const Gallery = () => {
    // const images = [ pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8, pic9, pic10, pic11, pic12, pic13, pic14, pic15, pic16, pic17, pic18, pic19, pic20, pic21, pic22, pic23, pic24, pic25, pic26, pic27, pic28, pic29, pic30, pic31, pic32, pic33, pic34, pic35, pic36, pic37, pic38, pic39, pic40, pic41, pic42, pic43, pic44, pic45, pic46, pic47, pic48, pic49, pic50, pic51, pic52, pic53, pic54, pic55, pic56, pic57, pic58, pic59, pic60, pic61, pic62, pic63, pic64, pic65, pic66, pic67, pic68 ]
    const images = [Dpic1, Dpic2, Dpic3, Dpic4, Dpic5, Dpic6, Dpic7, Dpic8, Dpic9, Dpic10, Dpic11, Dpic12, Dpic13, Dpic14, Dpic15, Dpic16, Dpic17, Dpic18 ]
        // [ Dpic19, Dpic20, Dpic21, Dpic22, Dpic23, Dpic24, Dpic25, Dpic26, Dpic27, Dpic28, Dpic29, Dpic30, Dpic31, Dpic32, Dpic33, Dpic34, Dpic35, Dpic36, Dpic37, Dpic38, Dpic39, Dpic40, Dpic41, Dpic42, Dpic43, Dpic44, Dpic45, Dpic46, Dpic47, Dpic48, Dpic49, Dpic50, Dpic51, Dpic52, Dpic53, Dpic54, Dpic55, Dpic56, Dpic57, Dpic58, Dpic59, Dpic60, Dpic61, Dpic62, Dpic63, Dpic64, Dpic65, Dpic66, Dpic67, Dpic68];

    return (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 p-4">
            {images.map((image, index) => (
                <div className='text-center p-2 border-2'>
                    <img key={index} src={image} alt={`img ${index + 1}`} className="w-full" />
                </div>
            ))}
        </div>
    );
};

export default Gallery;
