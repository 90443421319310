import React, { useState } from 'react'
import logo from '../images/COLLEGE LOGO (1).jpg'
import { Link } from 'react-router-dom';
import './NavBar.css';

const NavBar = () => {

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    return (
        <div className='w-screen'>
            <nav className="py-1 fixed z-10 left-0 top-0 w-screen bg-white">
                <div className="flex px-2 py-1 border-b-2 border-orange-400 shadow-xl w-screen lg:shadow-none lg:justify-center">
                    <Link to="/" className='mx-auto'>
                        <div className='flex'>
                            <img src={logo} alt="Logo" className='h-20 lg:h-20 border-r-2 pr-2 border-orange-400' />
                            <div className='ml-2 align-middle text-center'>
                                <p className='text-[7px] mb-0 customFont'>Prajna Education Trust (R.)</p>
                                <p className='text-6xl font-bold -mt-1 customFont'>PACE</p>
                                <p className='text-[8px] customFont -mt-1'>PRE-UNIVERSITY COLLEGE, SHIVAMOGGA</p>
                                {/* <p className='text-2xl ml-0 mb-1 font-bold lg:text-xl lg:ml-0'>PACE P.U.College</p>
                                <p className='text-xs text-orange-400 lg:text-md lg:mb-2'>|| ವಿದ್ಯಯಾ ಅಮೃತಂ ಅಶ್ನುತೇ  ||</p> */}
                            </div>
                        </div>
                    </Link>
                    <button className='text-3xl mr-3 block lg:hidden' onClick={toggleMenu}>
                        ☰
                    </button>
                </div>
                {showMenu && <div className={`lg:block lg:shadow-md`}>
                     <ul className='w-full items-center text-center space-y-6 lg:flex lg:space-y-0 lg:space-x-2 justify-around'>
                        <li data-aos="fade-right" data-aos-duration="100" className='w-full mt-4 border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none lg:mt-0' onClick={toggleMenu}>
                            <Link to="home">Home</Link>
                        </li>
                        {/* <li data-aos="fade-right" data-aos-duration="300" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu} >
                            <Link to="about">About</Link>
                        </li> */}
                        <li data-aos="fade-right" data-aos-duration="500" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="achievements">Achievements</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="700" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="faculties">Faculties</Link>
                        </li>

                        <li data-aos="fade-right" data-aos-duration="900" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="courses">Courses</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1100" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="coaching">Coaching</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1300" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="hostel">Hostel</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1400" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="facilities">Facilities</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1400" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="gallery">Gallery</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1500" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="testimonials">Testimonials</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1600" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="onlineExams">Online exams</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1700" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none' onClick={toggleMenu}>
                            <Link to="contact">Contact us</Link>
                        </li>
                    </ul>
                </div>}
                {!showMenu && <div className={`${showMenu ? "block" : "hidden"} lg:block py-3 lg:shadow-md`}>
                    <ul className='w-full items-center text-center space-y-6 lg:flex lg:space-y-0 justify-around'>
                        <li data-aos="fade-right" data-aos-duration="1100" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="home">Home</Link>
                        </li>
                        {/* <li data-aos="fade-right" data-aos-duration="1200" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="about">About</Link>
                        </li> */}
                        <li data-aos="fade-right" data-aos-duration="1300" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="achievements">Achievements</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1400" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="faculties">Faculties</Link>
                        </li>

                        <li data-aos="fade-right" data-aos-duration="1500" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="courses">Courses</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1600" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="coaching">Coaching</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1700" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="hostel">Hostel</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1800" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="facilities">Facilities</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1800" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="gallery">Gallery</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="1900" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="testimonials">Testimonials</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="2000" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="onlineExams">Online exams</Link>
                        </li>
                        <li data-aos="fade-right" data-aos-duration="2100" className='w-full border-b-1 shadow-sm rounded-lg lg:border-b-0 lg:shadow-none lg:w-auto hover:border-b-[1px] hover:border-orange-500 lg:rounded-none'>
                            <Link to="contact">Contact us</Link>
                        </li>
                    </ul>
                </div>}
            </nav>
        </div>
    )
}

export default NavBar