import React from 'react'
import principal from '../../images/principal.png'

const Content1 = () => {
    return (
        <div>
            <div class="w-[90%] mx-auto text-center px-8 py-4 mt-16 bg-orange-200 rounded-lg shadow-lg ">
                <div data-aos="fade-up" class="flex justify-center -mt-12">
                    <img class="object-cover w-20 h-20 border-2 border-blue-500 rounded-full " alt="Testimonial avatar" src={principal}/>
                </div>

                <h2 class="mt-2 text-md font-light italic text-gray-800  md:mt-0" data-aos="fade-up">Secretary & Principal</h2>

                <p class="mt-2 text-sm text-gray-600" data-aos="fade-up">PACE PU College is one of the prestigious colleges in Karnataka ,well known for its academic excellence. Over the years, we've worked hard to guide and support our students in achieving their goals. Our college focuses on nurturing students for all-rounded development and actively contributes to society. We offer extensive and integrated coaching for competitive  examinations like NEET, JEE, KCET, etc. Many of our students have achieved top rankings in state and national level competitive examinations, making us the best PU College in Shivamogga District. We are proud to have an excellent teaching faculty proficient in instructing both competitive and board exams. Our lecturers are highly qualified with exceptional communication skills and they are continuously enhancing their knowledge base to provide the best to our students.</p>
                <p class="mt-4 text-sm font-bold text-gray-600" data-aos="fade-up">Our Mission is to foster a safe, dynamic and respectful educational environment that cultivates a lifelong passion for learning. Also, our vision is to empower students on their transformative journey towards a brilliant and prosperous future.</p>

                <div class="flex justify-end mt-4" data-aos="fade-up">
                    <p class="text-lg font-medium text-blue-600 " tabindex="0" role="link">- Prof. B.N.Vishwanathaiah</p>
                </div>
            </div>
        </div>
    )
}

export default Content1