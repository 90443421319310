import React,{useEffect} from 'react'
import Carousel from './home/Carousel'
import Content1 from './home/Content1'
import Content2 from './home/Content2'

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Carousel/>
      <Content1/>
      <Content2/>
    </>
  )
}

export default About