import React, { useState } from 'react';
import { ReactComponent as Whatsapp } from '../images/whatsapp-svgrepo-com.svg';
import { ReactComponent as Phone } from '../images/phone-solid.svg';
import { ReactComponent as Mail } from '../images/envelope-regular.svg';
import { ReactComponent as ChatOpen } from '../images/bot-svgrepo-com.svg';
import { ReactComponent as ChatClose } from '../images/chatBotClose.svg';
import { ReactComponent as Form } from '../images/form.svg';
import { ReactComponent as Brocher } from '../images/brocherDownload.svg';
import { useNavigate } from "react-router-dom";
import paceBrocher from '../images/PACE.pdf';

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const openChatbot = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed bottom-6 left-6 rounded-3xl ">
      {!isOpen && <button
        className="p-2 bg-blue-500 text-white rounded-full"
        onClick={openChatbot}
      >
        <ChatOpen className='w-8 h-8'/>
      </button>}

      {isOpen && (
        <div className="p-1 rounded-lg">
          <div className="flex flex-col space-y-6">
            <a
              href={paceBrocher} 
              target="_blank"
              rel="noopener noreferrer"
            >
              <button onClick={openChatbot} className="p-2 md:p-1 bg-black text-white rounded-full">
                <Brocher className='w-8 h-8'/>
              </button>
            </a>
            <a
              href="https://wa.me/+918951721555"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button onClick={openChatbot} className="p-2 md:p-1 bg-green-500 text-white rounded-full">
                <Whatsapp className='w-8 h-8'/>
              </button>
            </a>
            <a href="tel:+918951921555">
              <button onClick={openChatbot} className="p-2 md:p-1 bg-blue-500 text-white rounded-full">
                <Phone className='w-8 h-8'/>
              </button>
            </a>
            <a href="mailto:admissions@pacepuc.com">
              <button onClick={openChatbot} className="p-2 md:p-1 bg-red-300 text-white rounded-full">
                <Mail className='w-8 h-8'/>
              </button>
            </a>
            <button onClick={openChatbot}>
              <div className="p-2 md:p-1 bg-blue-950 text-white rounded-full" onClick={()=>{navigate('/contact')}}>
                <Form className='w-8 h-8'/>
              </div>
            </button>
          </div>
          <div className="flex justify-start mt-2">
            <button
              className="p-2 mt-2 bg-red-500 text-white rounded-full"
              onClick={openChatbot}
            >
              <ChatClose className='w-5 h-5'/>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
