import React from 'react';
import eshwarappa from '../../images/Eshwarappa.png';
import Vishwanathaiah from '../../images/principal.png';
import Anand from '../../images/AnandVP.png';
import Kanthesh from '../../images/kanthesh.png';
import Shankaranarayana from '../../images/ShankaraT.png';
import Mythili from '../../images/MythiliT.png';

const Content2 = () => {
    return (
        <div className="bg-gray-200 p-4 md:p-8 lg:p-12 w-[90%] rounded-lg mx-auto mt-6 mb-4">
            <div className='text-center text-4xl font-bold mb-4'>
                Management
            </div>
            <div className="max-w-6xl mx-auto flex flex-wrap items-center justify-center gap-2">
                {/* 1st Image (Larger) */}
                <div className="w-[100%] sm:w-[70%] md:w-3/5 lg:w-1/3 p-4 border-2 shadow-lg" data-aos="fade-up" data-aos-duration="1500">
                    <div className="relative">
                        <img src={eshwarappa} alt="Image1" className="w-full h-auto rounded-full" />
                        <div className="absolute bottom-0 left-0 right-0 bg-white bg-opacity-70 p-2 text-center">
                            <p className='font-bold text-xl'>Sri K.S.Eshwarappa</p>
                            <p className='text-sm font-semibold'>President</p>
                        </div>
                    </div>
                </div>

                <div className='mx-auto flex flex-wrap items-center justify-around' data-aos="fade-up" data-aos-duration="1500">
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-2 border-2">
                        <div className="relative">
                            <img src={Anand} alt="Image2" className="w-1/2 sm:w-[70%] mx-auto h-36 rounded-full" />
                            <div className="p-2 text-center">
                                <p className='font-semibold'>Prof. H. Anand</p>
                                <p>Vice President</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-2 border-2" data-aos="fade-up" data-aos-duration="1500">
                        <div className="relative">
                            <img src={Vishwanathaiah} alt="Image3" className="w-1/2 sm:w-[70%] mx-auto h-36 rounded-full" />
                            <div className="p-2 text-center">
                                <p className='font-semibold'>Prof. B.N.Vishwanathaiah</p>
                                <p>Secretary & Principal</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-2 border-2" data-aos="fade-up" data-aos-duration="1500">
                        <div className="relative">
                            <img src={Kanthesh} alt="Image4" className="w-1/2 sm:w-[70%] mx-auto h-36 rounded-full" />
                            <div className="p-2 text-center">
                                <p className='font-semibold'>Sri K.E.Kanthesh</p>
                                <p>Treasurer</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-2 border-2" data-aos="fade-up" data-aos-duration="1500">
                        <div className="relative">
                            <img src={Shankaranarayana} alt="Image5" className="w-1/2 sm:w-[70%] mx-auto h-36 rounded-full" />
                            <div className="p-2 text-center">
                                <p className='font-semibold'>H.R.Shankaranarayana Shastry</p>
                                <p>Trustee</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 p-2 border-2" data-aos="fade-up" data-aos-duration="1500">
                        <div className="relative">
                            <img src={Mythili} alt="Image6" className="w-1/2 sm:w-[70%] mx-auto h-36 rounded-full" />
                            <div className="p-2 text-center">
                                <p className='font-semibold'>Dr. Mythili C</p>
                                <p>Trustee</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 5 Smaller Images */}

            </div>
        </div>
    );
};

export default Content2;
