import React, {useEffect} from 'react'
import Carousel from './Carousel'
import Content1 from './Content1'
import Content2 from './Content2'
import Content3 from './Content3'

const HomeMain = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
        <Carousel/>
        <Content1/>
        <Content3/>
        <Content2/>
    </>
  )
}

export default HomeMain