import React, { useState, useEffect } from "react";

// import CollegeMain from '../../images/Gallery/1.jpg'
// import HomeSlide1 from '../../images/HomeSlide1.png'
// import HomeSlide2 from '../../images/HomeSlide2.png'
// import HomeSlide3 from '../../images/HomeSlide3.png'
// import HomeSlide4 from '../../images/HomeSlide4.png'
// import HomeSlide5 from '../../images/HomeSlide5.png'
// import HomeSlide6 from '../../images/HomeSlide6.png'
// import HomeSlide7 from '../../images/HomeSlide7.png'
// import HomeSlide8 from '../../images/HomeSLide8.png'
// import HomeSlide9 from '../../images/HomeSlide9.png'
import paceBrocher from '../../images/PACE.pdf';
// import TopView from '../../images/TopView.png'
import HomeSlide1 from '../../images/homePage/homeSlider1.png';
import HomeSlide2 from '../../images/homePage/homeSlider2.png';
import HomeSlide3 from '../../images/homePage/homeSlider3.png';
import HomeSlide4 from '../../images/homePage/homeSlider4.png';
import HomeSlide5 from '../../images/homePage/homeSlider5.png';
import HomeSlide6 from '../../images/homePage/homeSlider6.png';
import HomeSlide7 from '../../images/homePage/homeslider7.png';
import HomeSlide8 from '../../images/homePage/homeSlider8.png';
import HomeSlide9 from '../../images/homePage/homeSlider9.png';


const photos = [HomeSlide1, HomeSlide2, HomeSlide3, HomeSlide4, HomeSlide5, HomeSlide6, HomeSlide7, HomeSlide8, HomeSlide9];

const Carousel = () => {



    const [activeIndex, setActiveIndex] = useState(0);

    const handleNext = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % photos.length);
    };

    const handlePrevious = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    };

    // Automatically change photos every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <div>
            <div className="bg-orange-300" data-aos="fade-down" data-aos-duration="1500">
                <marquee scrollamount='5' className="mt-2"> Registration Started for First PUC admission 2024-25 | NEET LONG TERM-2025 </marquee>
            </div>
            <div>
                <div className="relative overflow-hidden" data-aos="fade-up" data-aos-duration="1500">
                    <div
                        className="flex transition-transform duration-700 transform translate-x"
                        style={{
                            width: `${photos.length * 100}%`,
                            transform: `translateX(-${(activeIndex / photos.length) * 100}%)`,
                        }}
                    >
                        {photos.map((photo, index) => (
                            <div
                                key={index}
                                className="w-[90%] h-[300px] md:h-[380px] lg:h-[500px] relative"
                            >
                                <img className='h-[300px] md:h-[380px] lg:h-[500px] w-[90%] m-auto' src={photo} alt="" />
                                <button
                                    onClick={() => handlePrevious()}
                                    className="absolute top-1/2 left-2 z-10 text-black text-5xl"
                                >
                                    &#8249;
                                </button>
                                <button
                                    onClick={() => handleNext()}
                                    className="absolute top-1/2 right-2 z-10 text-black text-5xl"
                                >
                                    &#8250;
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="p-2 mt-2 text-center mx-auto text-blue-600 border-2">
                <a
                    href={paceBrocher} download="PACE Brocher.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg font-semibold"
                >
                    Click here to download the PACE Brochure..
                </a>
            </div>
        </div>
    )
}

export default Carousel