import React from 'react'
import jee from '../../images/others/jee.jpg'
import neet from '../../images/others/neet.jpg'
import kcet from '../../images/others/kcet.jpg'
import longTerm from '../../images/others/longterm.jpg'
import pcmb from '../../images/others/pcmb.jpg'
import pcmc from '../../images/others/pcmc.jpg'
import { useNavigate } from "react-router-dom";

const Content3 = () => {
  const navigate = useNavigate();

  return (
    <div className='w-[90%] mx-auto mt-6 gap-4'>
      <div class="w-full overflow-hidden bg-orange-200 rounded-lg shadow-lg border-2">
        <div class="items-center justify-between px-4 py-2 border-b-2 border-blue-500">
          <h1 class="text-2xl font-bold text-blue-500 text-center p-4" data-aos="fade-down">COURSES OFFERED</h1>
        </div>
        <div class="px-4 py-4 md:flex gap-4 justify-around mt-2">
          <div className='md:w-1/2' data-aos="fade-up" data-aos-duration="1500">
            <img className='w-full rounded-lg h-56 md:h-72' src={pcmb} alt="" />
            <div className='w-[100%] border-2 bg-white text-center mx-auto p-6 rounded-lg -mt-10 relative'>
              <p className='text-2xl font-bold text-blue-500'>PCMB</p>
            </div>
          </div>
          <div className='md:w-1/2' data-aos="fade-up" data-aos-duration="1500">
            <img className='w-full rounded-lg h-56 mt-4 md:mt-0 md:h-72' src={pcmc} alt="" />
            <div className='w-[100%] border-2 bg-white text-center mx-auto p-6 rounded-lg -mt-10 relative'>
              <p className='text-2xl font-bold text-blue-500'>PCMC</p>
            </div>
          </div>
        </div>
        <div class="items-center justify-between px-4 py-2 bg-white rounded-lg text-center">
          <button onClick={()=>{navigate('/courses')}} data-aos="fade-up" class="px-4 py-4 text-xs font-semibold text-white uppercase transition-colors duration-300 transform bg-blue-500 rounded-full hover:bg-gray-200 focus:bg-gray-400 focus:outline-none">More about Courses</button>
        </div>
      </div>


      <div class="w-full overflow-hidden bg-orange-200 rounded-lg shadow-lg mt-6 border-2">
        <div class="items-center justify-between px-4 py-2 border-b-2 border-blue-500">
          <h1 class="text-2xl font-bold text-blue-500 text-center p-4" data-aos="fade-down">COACHINGS OFFERED</h1>
        </div>
        <div class="px-4 py-4 md:flex flex-wrap gap-4 justify-around mt-2">
          <div className='md:w-[48%]' data-aos="fade-down" data-aos-duration="1500">
            <img className='w-full rounded-lg h-56 md:h-72' src={jee} alt="" />
            <div className='w-[100%] border-2 bg-white text-center mx-auto p-6 rounded-lg -mt-10 relative'>
              <p className='text-2xl font-bold text-blue-500'>JEE</p>
            </div>
          </div>
          <div className='md:w-[48%]' data-aos="fade-down" data-aos-duration="1500">
            <img className='w-full rounded-lg h-56 mt-4 md:mt-0 md:h-72' src={neet} alt="" />
            <div className='w-[100%] border-2 bg-white text-center mx-auto p-6 rounded-lg -mt-10 relative'>
              <p className='text-2xl font-bold text-blue-500'>NEET</p>
            </div>
          </div>
          <div className='md:w-[48%] mt-4 md:mt-0' data-aos="fade-up" data-aos-duration="1500">
            <img className='w-full rounded-lg h-56 md:h-72' src={kcet} alt="" />
            <div className='w-[100%] border-2 bg-white text-center mx-auto p-6 rounded-lg -mt-10 relative'>
              <p className='text-2xl font-bold text-blue-500'>KCET</p>
            </div>
          </div>
          <div className='md:w-[48%]' data-aos="fade-up" data-aos-duration="1500">
            <img className='w-full rounded-lg h-56 mt-4 md:mt-0 md:h-72' src={longTerm} alt="" />
            <div className='w-[100%] border-2 bg-white text-center mx-auto p-6 rounded-lg -mt-10 relative'>
              <p className='text-2xl font-bold text-blue-500'>LONG TERM</p>
            </div>
          </div>
        </div>
        <div class="items-center justify-between px-4 py-2 bg-white rounded-lg text-center">
          <button onClick={()=>{navigate('/coaching')}} data-aos="fade-up" class="px-4 py-4 text-xs font-semibold text-white uppercase transition-colors duration-300 transform bg-blue-500 rounded-full hover:bg-gray-200 focus:bg-gray-400 focus:outline-none">More about Coaching</button>
        </div>
      </div>

      {/* <div class="w-full overflow-hidden bg-white rounded-lg shadow-lg mt-4 dark:bg-gray-800">
        <div class="items-center justify-between px-4 py-2 bg-gray-900">
          <h1 class="text-lg font-bold text-white text-center">Coachings Offered</h1>
        </div>
        <div class="px-4 py-4 flex gap-2 ">
          <div className='w-1/4'>
            <img className='rounded-full' src={jee} alt="" />
          </div>
          <div className='w-1/4'>
            <img className='rounded-full' src={neet} alt="" />
          </div>
          <div className='w-1/4'>
            <img className='rounded-full' src={kcet} alt="" />
          </div>
          <div className='w-1/4'>
            <img className='rounded-full' src={longTerm} alt="" />
          </div>
        </div>
        <div class="items-center justify-between px-4 py-2 bg-gray-900 text-center mt-1">
          <button onClick={navigateTo2} class="px-2 py-1 text-xs font-semibold text-gray-900 uppercase transition-colors duration-300 transform bg-white rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none">More about Coachings</button>
        </div>
      </div> */}
    </div>
  )
}

export default Content3