import React, {useEffect} from 'react';
import first from '../images/hostel/enviornment.jpg';
import second from '../images/hostel/computer.jpg';
import third from '../images/hostel/mobile.jpg';
import fourth from '../images/hostel/interaction.jpg';
import fifth from '../images/hostel/security.jpg';

const Hostel = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='p-4'>
      <div className='text-center' data-aos="fade-up" data-aos-duration="1500">
        <h2 className='font-bold text-4xl mb-4'>Our PACE hostel</h2>
        <p class="mb-3 text-gray-500 dark:text-gray-400">At PACE, our hostel facilities stand out with a commitment to fostering a conducive learning and growth environment. Renowned subject experts visit daily, providing valuable insights, motivation, and addressing doubts in regular classes. Our hostel boasts fully equipped blocks, distinct for boys and girls, nestled in lush green surroundings with picturesque views from every room. Each room comprises a Study table, Wardrobe, Book rack, etc.</p>
        <p class="mb-3 text-gray-500 dark:text-gray-400">An important aspect of our hostel policy is the restriction on mobile devices within the hostel premises. This intentional decision aims to channel the focus of teenage students towards their education, creating a conducive atmosphere for concentrated study and minimizing distractions. However, recognizing the importance of technology in education, the college provides students with access to computers and tablets for study purposes. This balanced approach ensures that students can leverage technological resources for academic advancement while maintaining a disciplined and distraction-free living space within the hostel.</p>
        <p class="mb-3 text-gray-500 dark:text-gray-400">Choosing PACE hostel life not only ensures comfortable and well-equipped living spaces but also provides a unique and advantageous learning environment that emphasizes the importance of focused education and responsible use of technology.</p>
      </div>
      <div className="max-w-5xl mx-auto text-center mt-24" data-aos="fade-up" data-aos-duration="1500">
        <h2 className="text-2xl font-bold sm:text-3xl">Hostel Policy at PACE Institute</h2>
        <p className="mt-4 text-lg dark:text-gray-400">At PACE Institute, we understand the significance of a conducive and disciplined environment for academic excellence. Our hostel policy is crafted to create a focused and supportive living space that complements the educational journey of our students. Here are the key aspects of our hostel policy:</p>
      </div>
      <section className="p-4 lg:p-8 dark:bg-gray-800 dark:text-gray-100">
        <div className="container mx-auto space-y-12">
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row" data-aos="fade-up" data-aos-duration="1500">
            <img src={first} alt="" className="h-60 dark:bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-4 dark:bg-gray-900">
              <h3 className="text-2xl font-semibold">Study-Friendly Infrastructure:</h3>
              <p className="my-2 dark:text-gray-400">The hostel is equipped with fully furnished rooms, providing a comfortable and productive living space.</p>
              <p className="my-2 dark:text-gray-400">Each student is allocated a combined room with a Study table, Wardrobe, Book rack, and a single Bed, facilitating an efficient study environment.</p>
              <p className="my-2 dark:text-gray-400">Common study/library areas and indoor and outdoor play zones are available to promote collaborative learning and relaxation.</p>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse" data-aos="fade-up" data-aos-duration="1500">
            <img src={second} alt="" className="h-60 dark:bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-900">
              <h3 className="text-2xl font-semibold">Computer and Tablet Access:</h3>
              <p className="my-2 dark:text-gray-400">Recognizing the importance of technology in education, the college provides students with access to computers and tablets for study purposes.</p>
              <p className="my-2 dark:text-gray-400">This ensures that students can leverage technological resources for academic advancement while maintaining a disciplined and distraction-free living space within the hostel.</p>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row" data-aos="fade-up" data-aos-duration="1500">
            <img src={third} alt="" className="h-60 dark:bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-900">
              <h3 className="text-2xl font-semibold">Mobile Devices Restriction:</h3>
              <p className="my-2 dark:text-gray-400">Mobile phones are not permitted within the hostel premises.</p>
              <p className="my-2 dark:text-gray-400">This intentional restriction is aimed at fostering an environment that encourages students to concentrate on their studies without unnecessary distractions.</p>
              <p className="my-2 dark:text-gray-400">We believe that limiting the use of mobile devices contributes to a more focused and disciplined academic atmosphere.</p>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row-reverse" data-aos="fade-up" data-aos-duration="1500">
            <img src={fourth} alt="" className="h-60 dark:bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-900">
              <h3 className="text-2xl font-semibold">Expert Interaction:</h3>
              <p className="my-2 dark:text-gray-400">Subject experts visit the hostel regularly, providing valuable insights, motivation, and addressing doubts in daily classes.</p>
              <p className="my-2 dark:text-gray-400">This interaction enhances the academic experience of students, offering personalized guidance and support.</p>
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-md shadow-sm lg:flex-row" data-aos="fade-up" data-aos-duration="1500">
            <img src={fifth} alt="" className="h-60 dark:bg-gray-500 aspect-video" />
            <div className="flex flex-col justify-center flex-1 p-6 dark:bg-gray-900">
              <h3 className="text-2xl font-semibold">Security and Support:</h3>
              <p className="my-2 dark:text-gray-400">Each floor is equipped with a caretaker's room to ensure a secure and supportive living environment.</p>
              <p className="my-2 dark:text-gray-400">The hostel has separate blocks for boys and girls, situated in lush green surroundings with picturesque views from all rooms.</p>
            </div>
          </div>
        </div>
      </section>
      <div className="max-w-5xlxl mx-auto text-center">
        <p className="mt-4 text-lg dark:text-gray-400">By adhering to these policies, PACE Institute aims to create a nurturing environment where students can focus on their studies, collaborate with peers, and make the most of the available resources. Our commitment is to provide not just accommodation but a holistic educational experience that prepares students for success.</p>
      </div>
    </div>
  )
}

export default Hostel