import { useState } from 'react';
import logo from './images/COLLEGE_LOGO.jpg';
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import HomeMain from "./pages/home/HomeMain";
import ChatBot from "./components/ChatBot";
import NotFound from "./pages/NotFound";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import { BrowserRouter as Main, Routes, Route } from "react-router-dom";
import Achievements from "./pages/Achievements";
import About from "./pages/About";
import Faculties from "./pages/Faculties";
import Courses from "./pages/Courses";
import Coaching from "./pages/Coaching";
import Hostel from "./pages/Hostel";
import Facilities from "./pages/Facilities";
import Testimonials from "./pages/Testimonials";
import OnlineExams from "./pages/OnlineExams";
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';

function App() {

  const [showWelcome, setShowWelcome] = useState(true);

  useEffect(() => {
    // Set a timer to hide the welcome page after 5 seconds
    const timer = setTimeout(() => {
      setShowWelcome(false);
    }, 2500);

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 300 })
  })

  return (
    <div>
      <Main>
        {showWelcome && (
          <div className=" text-center fixed top-0 left-0 w-full h-full bg-white z-50 flex flex-col items-center justify-center">
            <h1 className="text-4xl font-bold text-orange-400" data-aos="zoom-out" data-aos-duration="1500">Welcome to PACE P.U College !</h1>
            <img src={logo} className='h-80 mt-4' alt="" data-aos="flip-left" data-aos-duration="1500" />
          </div>
        )}
        <NavBar />
        <div className="mt-[100px] lg:mt-[145px]">
          <Routes>
            <Route exact path="/" element={<HomeMain />} />
            <Route exact path="/home" element={<HomeMain />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/achievements" element={<Achievements />} />
            <Route exact path="/faculties" element={<Faculties />} />
            <Route exact path="/courses" element={<Courses />} />
            <Route exact path="/coaching" element={<Coaching />} />
            <Route exact path="/hostel" element={<Hostel />} />
            <Route exact path="/facilities" element={<Facilities />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/testimonials" element={<Testimonials />} />
            <Route exact path="/onlineExams" element={<OnlineExams />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
        <ChatBot />
      </Main>
    </div>
  );
}

export default App;
