import React, { useEffect } from 'react'

const Coaching = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='p-4 md:p-8 bg-blue-200'>

      <h2 className='text-3xl font-bold' data-aos="fade-right" data-aos-duration="1500">Coaching Offered at PACE</h2>

      <div class="space-y-4 mt-3" data-aos="fade-up" data-aos-duration="1500">
        <details
          class="group rounded-lg p-6 [&_summary::-webkit-details-marker]:hidden"
          open
        >
          <summary
            class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
          >
            <h2 class="font-bold text-xl">
              JEE Coaching at PACE
            </h2>

            <span class="relative h-5 w-5 shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 h-5 w-5 opacity-100 group-open:opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 h-5 w-5 opacity-0 group-open:opacity-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </summary>

          <p class="mt-4 leading-relaxed text-gray-700">
            At PACE, our commitment to student success goes beyond regular classroom instruction. JEE aspirants are provided with a systematic and targeted approach through Daily Practice Papers (DPP). These are designed to reinforce concepts learned in class and enhance problem-solving skills. Each day, students receive a set of carefully curated problems related to Physics, Chemistry and Mathematics, ensuring consistent and focused practice.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            The DPP system serves as a valuable tool for self-assessment, allowing students to gauge their understanding of topics and identify areas that may require additional attention. This daily practice not only solidifies theoretical knowledge but also sharpen the analytical and problem-solving abilities crucial for success in JEE.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700 font-bold"> Regular Online Exams Conducted by NTA: </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Recognizing the importance of real exam simulation, PACE integrates regular online exams conducted by the National Testing Agency (NTA) into the JEE coaching program. These exams mimic the format and structure of the actual JEE, providing students with a firsthand experience of the exam environment. This practice is instrumental in helping students develop time-management skills, build confidence and adapt to the challenges of a computer-based testing system.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            The results of these online exams are analysed to provide personalized feedback to students. This feedback not only highlights their strengths but also pinpoints areas that need improvement. It enables our faculty to tailor their guidance and support, ensuring that each student receives targeted assistance in areas where they can maximize their performance.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700 mb-4">
            By incorporating DPP and online exams into the JEE coaching framework, PACE aims to create a comprehensive and dynamic learning experience. The combination of regular problem-solving practice and simulated exam conditions prepares students not only for the academic accuracy of JEE but also for the psychological aspects of the examination process. At PACE, we strive to empower our students with the tools they need to excel in their JEE journey.
          </p>
          <a href='https://jeemain.nta.nic.in/information-bulletin/' className='text-blue-700' target='_blank' rel="noreferrer">Click here for more info</a>
        </details>

        <details
          class="group rounded-lg p-6 [&_summary::-webkit-details-marker]:hidden"
        >
          <summary
            class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
          >
            <h2 class="font-bold text-xl">
              NEET Coaching at PACE
            </h2>

            <span class="relative h-5 w-5 shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 opacity-100 group-open:opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 opacity-0 group-open:opacity-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </summary>

          <p class="mt-4 leading-relaxed text-gray-700">
            PACE's NEET coaching program is designed to revolutionize the learning experience. The curriculum, spanning Physics, Chemistry and Biology, is enriched with Daily Practice Papers (DPP) to ensure a solid understanding of the subjects.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Regular exams, conducted at a college level, go beyond theoretical assessments. They simulate the actual NEET exam environment, equipping students with the confidence and skills needed to tackle the challenges of the medical entrance test.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Our integrated coaching system is a key strength. It seamlessly blends board-level teachings with NEET-level questions, providing students with a comprehensive and interconnected educational journey. Immediate discussions on NEET-level questions right after board-level teaching sessions enhance students' conceptual grasp and problem-solving abilities.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            The faculty at PACE doesn't just focus on theoretical knowledge; practical application is paramount. This approach ensures that students not only understand concepts but also can apply them effectively in real-world scenarios, preparing them for the practical challenges of the medical entrance exam.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Regular exams form the backbone of our coaching methodology. They serve as crucial checkpoints, fostering confidence and allowing students to track their progress over time. This continuous assessment approach is integral to our commitment to excellence.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Personalized attention and support are hallmarks of PACE's coaching philosophy. Our experienced faculty addresses individual learning requirements, ensuring that each student receives the guidance they need to excel in the NEET exam.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700 mb-4">
            Our state-of-the-art facilities create a learning environment conducive to focused study and academic excellence. PACE doesn't just aim to prepare students for the NEET; we strive to develop critical thinking and analytical skills essential for their future success in the medical field and beyond.
          </p>
          <a href='https://neet.nta.nic.in/information-bulletin/' className='text-blue-700'  target='_blank' rel="noreferrer">Click here for more info</a>
        </details>

        <details
          class="group rounded-lg p-6 [&_summary::-webkit-details-marker]:hidden"
        >
          <summary
            class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
          >
            <h2 class="font-bold text-xl">
              KCET Coaching at PACE
            </h2>

            <span class="relative h-5 w-5 shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 opacity-100 group-open:opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 opacity-0 group-open:opacity-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </summary>

          <p class="mt-4 leading-relaxed text-gray-700">
            PACE's KCET coaching program is designed to provide a comprehensive understanding of Physics, Chemistry, Mathematics and Biology, supplemented by Daily Practice Papers (DPP).
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Regular college level exams are an integral part of our coaching, offering students a real-time assessment of their readiness for the KCET.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Our integrated coaching system seamlessly merges board-level teachings with KCET-level questions, ensuring a smooth transition and a deeper understanding of concepts.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Immediate discussions on KCET-level questions after board-level teaching enhance students' problem-solving skills and prepare them for the challenges of the entrance exam.
          </p>
          {/* <p class="mt-4 leading-relaxed text-gray-700">
            The faculty at PACE, with their expertise, not only imparts theoretical knowledge but also emphasizes the practical application of concepts, equipping students for success.
          </p> */}
          <p class="mt-4 leading-relaxed text-gray-700">
            Regular exams play a crucial role in building students' confidence, allowing them to track their progress and identify areas for improvement.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700 mb-4">
            PACE's commitment to excellence is evident in the personalized attention and support provided to each student, addressing their unique learning needs.
          </p>
          {/* <p class="mt-4 leading-relaxed text-gray-700">
            Our state-of-the-art facilities create an environment conducive to focused study, fostering academic excellence among our students.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            PACE not only prepares students for the KCET but also instils critical thinking and analytical skills essential for their future success.
          </p> */}
          <a href='https://cetonline.karnataka.gov.in/kea/indexnew' className='text-blue-700'  target='_blank' rel="noreferrer">Click here for more info</a>
        </details>

        <details
          class="group rounded-lg p-6 [&_summary::-webkit-details-marker]:hidden"
        >
          <summary
            class="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900"
          >
            <h2 class="font-bold text-xl">
              LONG TERM NEET Coaching at PACE
            </h2>

            <span class="relative h-5 w-5 shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 opacity-100 group-open:opacity-0"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute inset-0 opacity-0 group-open:opacity-100"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </summary>

          <p class="mt-4 leading-relaxed text-gray-700">
            PACE's long-term coaching programs take a comprehensive and sustained approach to NEET exams, ensuring that students receive Daily Practice Papers (DPP) for every subject.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            The curriculum incorporates regular exams as a fundamental component, offering students consistent evaluation and a confidence boost throughout their preparation.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Our experienced faculty goes beyond theoretical teachings, emphasizing the practical application of concepts to ready students for the intricacies of competitive exams.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            The pivotal role of regular exams in building students' confidence, aiding in progress assessment and pinpointing areas for improvement remains unchanged.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700">
            Daily Practice Problems (DPP) continue to be an integral aspect of our coaching methodology, engaging students in ongoing learning and reinforcing their comprehension.
          </p>
          {/* <p class="mt-4 leading-relaxed text-gray-700">
            PACE's commitment to excellence is evident in the personalized attention and support provided to address the unique learning requirements of each student.
          </p> */}
          <p class="mt-4 leading-relaxed text-gray-700">
            Our state-of-the-art facilities persist in creating a conducive learning environment, fostering focused study and promoting academic excellence.
          </p>
          <p class="mt-4 leading-relaxed text-gray-700 mb-4">
            PACE maintains its dual focus not only preparing students for competitive exams but also cultivating critical thinking and analytical skills crucial for their future success.
          </p>
          <a href='https://neet.nta.nic.in/information-bulletin/' className='text-blue-700'  target='_blank' rel="noreferrer">Click here for more info</a>
        </details>
      </div>
    </div>
  )
}

export default Coaching