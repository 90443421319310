import React, {useEffect} from 'react'

const OnlineExams = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div class="flow-root w-[80%] mx-auto mt-36">
      <h2 className='text-2xl font-bold'>To Succeed in ONLINE EXAMINATION</h2>
      <dl class="-my-3 divide-y divide-gray-100 text-sm mt-4">
        <div
          class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
        >
          <dt class="font-medium text-gray-900">1.	Time Management is Key</dt>
          <dd class="text-gray-700 sm:col-span-2">Allocate time for each section/question. Monitor the clock for overall exam duration. Prioritize and strategize question tackling
          </dd>
        </div>

        {/* <div
          class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
        >
          <dt class="font-medium text-gray-900">2. Back Up Your Work</dt>
          <dd class="text-gray-700 sm:col-span-2">Save answers periodically. Type longer responses in a separate document
          </dd>
        </div> */}

        <div
          class="grid grid-cols-1 gap-1 py-3 even:bg-gray-50 sm:grid-cols-3 sm:gap-4"
        >
          <dt class="font-medium text-gray-900">2. Read Instructions Carefully</dt>
          <dd class="text-gray-700 sm:col-span-2">Thoroughly read section and question instructions. Clarify uncertainties before starting.
          </dd>
        </div>
      </dl>

      <div className='text-center mt-6 text-xl'>
      <a href='https://pacepucollege.thinkexam.com/' target='_blank' class="text-blue-500 underline font-medium tracking-wide">
        Click here 
      </a><span> To start the exam</span>
      </div>
    </div>
  )
}

export default OnlineExams