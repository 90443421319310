import logo from '../images/COLLEGE_LOGO.jpg';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Whatsapp } from '../images/whatsapp-svgrepo-com.svg';
import { ReactComponent as Phone } from '../images/phone-solid.svg';
import { ReactComponent as Mail } from '../images/envelope-regular.svg';
import { ReactComponent as Form } from '../images/form.svg';
import { ReactComponent as Brocher } from '../images/brocherDownload.svg';
import paceBrocher from '../images/PACE.pdf';
import React from 'react'

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="text-gray-500 bg-white mt-5 px-4 py-5 max-w-screen-xl mx-auto md:px-8 border border-t-2 align-middle items-center text-center">
      <div className="gap-6 justify-between md:flex">
        <div className="flex-1">
          <div>
            <img src={logo} className="w-32 mx-auto" alt='LOGO' />
            <p className="leading-relaxed mt-2 text-[15px]">
              PACE is a top science PU college in Karnataka, situated in Shivamogga, that extensively prepares students for a wide range of competitive examinations such as JEE, NEET, KCET and more.
            </p>
          </div>
        </div>
        <div className="flex-1 mt-10 md:pl-8 space-y-4 items-center justify-between sm:flex md:space-y-0 md:mt-0">
          <ul className="space-y-2 mx-auto" >
            <h4 className="text-gray-800 font-medium">
              GoTo
            </h4>
            <li>
              <button onClick={() => { navigate('/home') }} className="hover:underline hover:text-indigo-600"> Home </button>
            </li>
            <li>
              <button onClick={() => { navigate('/courses') }} className="hover:underline hover:text-indigo-600"> Courses </button>
            </li>
            <li>
              <button onClick={() => { navigate('/coaching') }} className="hover:underline hover:text-indigo-600"> Coaching </button>
            </li>
            <li>
              <button onClick={() => { navigate('/facilities') }} className="hover:underline hover:text-indigo-600"> Facilities </button>
            </li>
            <li>
              <button onClick={() => { navigate('/contact') }} className="hover:underline hover:text-indigo-600"> Contact us </button>
            </li>
            <li>
              <button onClick={() => { navigate('/gallery') }} className="hover:underline hover:text-indigo-600"> Gallery </button>
            </li>
            <li className='flex gap-4 justify-around py-4'>
              <a
                href={paceBrocher} download="PACE Brocher.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="p-2 md:p-1 bg-black text-white rounded-full">
                  <Brocher className='w-8 h-8' />
                </button>
              </a>
              <a
                href="https://wa.me/+918951721555"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="p-2 md:p-1 bg-green-500 text-white rounded-full">
                  <Whatsapp className='w-8 h-8' />
                </button>
              </a>
              <a href="tel:+918951921555">
                <button className="p-2 md:p-1 bg-blue-500 text-white rounded-full">
                  <Phone className='w-8 h-8' />
                </button>
              </a>
              <a href="mailto:admissions@pacepuc.com">
                <button className="p-2 md:p-1 bg-red-300 text-white rounded-full">
                  <Mail className='w-8 h-8' />
                </button>
              </a>
              <button>
                <div className="p-2 md:p-1 bg-blue-950 text-white rounded-full" onClick={() => { navigate('/contact') }}>
                  <Form className='w-8 h-8' />
                </div>
              </button>

            </li>
          </ul>
        </div>
        <div>
          <div className="h-36 lg:h-[100%] mt-4">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3872.1622549318245!2d75.59394077336938!3d13.94894279270213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbbaeca6929a21d%3A0xb0e5a879d413894d!2sPACE%20PU%20College!5e0!3m2!1sen!2sin!4v1700650468126!5m2!1sen!2sin" title="Iframe of the PACE PU shivamogga" width="100%" height="100%" allowfullscreen={true} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <div className="mt-8 py-6 border-t items-center justify-between sm:flex">
        <div className="mt-4 sm:mt-0 md:ml-10">
          Copyright &copy; 2024 PACE PU College, Shivamogga.
        </div>
        <div className="mt-6 sm:mt-0">
          <ul className="flex items-center align-middle pl-12 md:pl-0 text-center">
            <p>
              <a className="hover:underline" href="tel:+918951921555">89519 21555</a> | <a className="hover:underline" href="tel:+918951821555">89518 21555</a> | <a className="hover:underline" href="tel:+918951721555">89517 21555</a> <br />
              <a className="hover:underline" href="tel:+919448884903">94488 84903</a> <span>| <a className="hover:underline" href="tel:+919900148983"> 99001 48983</a></span>
            </p>

          </ul>
          <p className='text-center md:items-center md:space-x-4 mt-2'>Web Developed by <a href="http://webright.in/" rel="noreferrer" target="_blank" className='text-blue-500 hover:underline' title="PaceLocationMap">webright.in</a></p>
        </div>
      </div>
      <style jsx>{`
                .svg-icon path,
                .svg-icon polygon,
                .svg-icon rect {
                    fill: currentColor;
                }
            `}</style>
    </footer>
  )
}

export default Footer
