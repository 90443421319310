import React, { useEffect } from 'react';
import Chinmay from '../images/testimonials/Chinmaya.png';
import Maadhyan from '../images/testimonials/Maadhyam.png';
import Smruthi from '../images/testimonials/Smruthi.png';
import Apthi from '../images/testimonials/apthi.png';
import Koushik from '../images/testimonials/koushik.png';

const Testimonials = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <section>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24" data-aos="fade-up" data-aos-duration="1500">
        <div class="grid grid-cols-1">
          <div class="w-full max-w-5xl mx-auto my-4 bg-white shadow-xl rounded-xl">
            <img alt="team" class="flex-shrink-0 object-cover object-center w-40 h-40 mx-auto -mt-8 rounded-full shadow-xl aboslute" src={Apthi} />

            <div class="p-6 lg:text-center">
              <h4 class="mt-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-center">Apthi HN</h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">(2023-24 Batch)</h4>
              <p class="mt-5 text-base leading-relaxed text-gray-500">Our institution upholds value-based education, providing an ambience and infrastructure that aids concentration in studies. Committed lecturers and a supportive atmosphere make me feel grateful, helping me work towards fulfilling my dreams and aspirations. Moreover, care from the hostel wardens treating us like their own kids make our learning experience truly exceptional. The hostel provides 24/7 medical care for the students, which gives us the feeling of homely atmosphere.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24" data-aos="fade-up" data-aos-duration="1500">
        <div class="grid grid-cols-1">
          <div class="w-full max-w-5xl mx-auto my-4 bg-white shadow-xl rounded-xl">
            <img alt="team" class="flex-shrink-0 object-cover object-center w-40 h-40 mx-auto -mt-8 rounded-full shadow-xl aboslute" src={Chinmay} />

            <div class="p-6 lg:text-center">
              <h4 class="mt-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-center">Chinmaya P</h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">(2021- 22 Batch)</h4>
              <p class="mt-5 text-base leading-relaxed text-gray-500">I can confidently say that PACE College offers the best facilities for skill and knowledge development. The integrated program coupled with experienced faculty, sets our college apart from others in the area. The friendly atmosphere and individual attention through mentoring system boost my self-confidence, giving me the assurance to reach my lifetime goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl" data-aos="fade-up" data-aos-duration="1500">
        <div class="grid grid-cols-1">
          <div class="w-full max-w-5xl mx-auto my-4 bg-white shadow-xl rounded-xl">
            <img alt="team" class="flex-shrink-0 object-cover object-center w-40 h-40 mx-auto -mt-8 rounded-full shadow-xl aboslute" src={Maadhyan} />

            <div class="p-6 lg:text-center">
              <h4 class="mt-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-center">Maadhyam M Swamy</h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">(2021- 22 Batch)</h4>
              <p class="mt-5 text-base leading-relaxed text-gray-500">I appreciate the opportunities PACE College has provided for my growth and skill exploration. The integration of sports, cultural and education has been crucial for maintaining mental health in the preparation for competitive examinations such as JEE, NEET and KCET. I am thankful for the supportive environment that encourages academic excellence.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl" data-aos="fade-up" data-aos-duration="1500">
        <div class="grid grid-cols-1">
          <div class="w-full max-w-5xl mx-auto my-4 bg-white shadow-xl rounded-xl">
            <img alt="team" class="flex-shrink-0 object-cover object-center w-40 h-40 mx-auto -mt-8 rounded-full shadow-xl aboslute" src={Smruthi} />

            <div class="p-6 lg:text-center">
              <h4 class="mt-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-center">Smruthi D Kumatakar </h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">(2021- 22 Batch)</h4>
              <p class="mt-5 text-base leading-relaxed text-gray-500">The college not only impact on knowledge but also encourages life skills in us by conducting at least one motivational, counselling and life turning seminars to make us socially responsible citizen. We have excellent teaching faculty who instils confidence and ensure good result. I am fortunate to get an opportunity to study at PACE P.U college.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl" data-aos="fade-up" data-aos-duration="1500">
        <div class="grid grid-cols-1">
          <div class="w-full max-w-5xl mx-auto my-4 bg-white shadow-xl rounded-xl">
            <img alt="team" class="flex-shrink-0 object-cover object-center w-40 h-40 mx-auto -mt-8 rounded-full shadow-xl aboslute" src={Koushik} />

            <div class="p-6 lg:text-center">
              <h4 class="mt-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-center">Koushik H S </h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">(2021-22 Batch)</h4>
              <p class="mt-5 text-base leading-relaxed text-gray-500">Integrated system which encourages to learn JEE/NEET/KCET level immediately after the board level enable us to study with determination. Also, everyday set of lecturer stays in the hostel till 8.30 PM which helps us to solve the doubts on same day. I feel fortunate to be the part of this college as I strived to fulfil my dreams.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="relative items-center w-full px-5 mx-auto md:px-12 lg:px-24 max-w-7xl" data-aos="fade-up" data-aos-duration="1500">
        <div class="grid grid-cols-1">
          <div class="w-full max-w-5xl mx-auto my-4 bg-white shadow-xl rounded-xl">
            {/* <img alt="team" class="flex-shrink-0 object-cover object-center w-40 h-40 mx-auto -mt-8 rounded-full shadow-xl aboslute" src={Koushik} /> */}
            <p className='text-center text-8xl'>``</p>

            <div class="p-6 -mt-16 lg:text-center">
              <h4 class="text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-center">Sathish</h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">Businessman</h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">Parent of Sanjana S (2022-23 Batch)</h4>
              <p class="mt-5 text-base leading-relaxed text-gray-500">ಪಿ.ಯು.ಸಿ ಶಿಕ್ಷಣಕ್ಕೆ ಅನ್ವರ್ಥ ನಾಮವಾಗಿದ್ದ ದಕ್ಷಿಣ ಕನ್ನಡದ ಪ್ರತಿಷ್ಠಿತ ಕಾಲೇಜುಗಳನ್ನು ಸ್ಪರ್ದಾತ್ಮಕ ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ಹಿಂದಿಕ್ಕಿ ತನ್ನದೇ ಛಾಪು ಮೂಡಿಸಿರುವ ನಮ್ಮ ಶಿವಮೊಗ್ಗ ಜಿಲ್ಲೆಯ ಹೆಮ್ಮೆಯ ಪೇಸ್ ಪದವಿ ಪೂರ್ವ ಕಾಲೇಜಿನಲ್ಲಿ ನನ್ನ ಮಗಳು ವ್ಯಾಸಂಗ ಮಾಡುತ್ತಿರುವುದೇ ಸಂತಸದ ವಿಷಯ.ಕಲಿಕೆಯ ಜೊತೆಗೆ ಶಿಸ್ತು ಕೂಡ ಮಗಳ ದಿನಚರಿಯ ಮೇಲೆ ಮಹತ್ವದ ಪರಿಣಾಮ ಬೀರುತ್ತಿದೆ. ಲಕ್ಷಾಂತರ ಮಂದಿ ಬರೆಯುವಂತಹ ಸ್ಪರ್ಧಾತ್ಮಕ ಪರೀಕ್ಷೆಗಳಲ್ಲಿ ಅತ್ತ್ಯುನ್ನತ ಅಂಕ ಗಳಿಸುವಂತಹ ಆತ್ಮವಿಶ್ವಾಸ ಆಕೆಯಲ್ಲಿ ಮೂಡಿರುವುದು ನೆಮ್ಮದಿಯ ವಿಚಾರ.</p>
            </div>

            <p className='text-center text-8xl'>``</p>
          </div>
        </div>
      </div>
      <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl" data-aos="fade-up" data-aos-duration="1500">
        <div class="grid grid-cols-1">
          <div class="w-full max-w-5xl mx-auto my-4 bg-white shadow-xl rounded-xl">
            {/* <img alt="team" class="flex-shrink-0 object-cover object-center w-40 h-40 mx-auto -mt-8 rounded-full shadow-xl aboslute" src={Koushik} /> */}

            <p className='text-center text-8xl'>``</p>

            <div class="p-6 -mt-20 lg:text-center">
              <h4 class="mt-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600 lg:text-3xl text-center">Prakash G </h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center">HAL Employee Bangalore
              </h4>
              <h4 class="mt-2 text-lg leading-none tracking-tighter lg:text-xl text-center"> Parent of Nayana (2023-24 Batch)</h4>
              <p class="mt-5 text-base leading-relaxed text-gray-500">ಒಂದು ದಿನವೂ ಕೂಡ ಮಗಳನ್ನು ಬಿಟ್ಟಿರದ ನಾವು ಈಗ 300 Km ದೂರದಲ್ಲಿರುವ ಶಿವಮೊಗ್ಗದ ಪೇಸ್ ಕಾಲೇಜಿನಲ್ಲಿ ಪಿ.ಯು.ಸಿ. ಶಿಕ್ಷಣಕ್ಕಾಗಿ ಮಗಳನ್ನು ಕಳುಹಿಸಿದ್ದೇವೆ ಎಂದರೆ ಅಲ್ಲಿಯ ಉತ್ತಮ ಶಿಕ್ಷಣ,ಹಿತವಾದ ಆಹಾರ ಹಾಗು ಲವಲವಿಕೆಯ ವಾತಾವರಣ ಹೊಂದಿರುವ ಹಾಸ್ಟೆಲ್ ಮೂಲಕಾರಣ. ಪೋಷಕರಿಂದ ದೂರವಿರುವ  ಮಕ್ಕಳ ಆರೋಗ್ಯದ ಬಗೆಗಿನ ಹಾಸ್ಟೆಲ್ ವಾರ್ಡನ್ ಗಳ ಕಾಳಜಿ, ಸಕಾಲಕ್ಕೆ ತಜ್ಞರಿಂದ COUNSELLING ಹಾಗು MOTIVATIONAL ತರಗತಿಗಳು ನಮ್ಮನ್ನು ಆಕರ್ಷಿಸಿವೆ.</p>
            </div>

            <p className='text-center text-8xl'>``</p>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials