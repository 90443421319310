import React, { useEffect } from 'react'

const Courses = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className='p-4'>
      <div>
        <h2 className='font-bold text-3xl mb-4' data-aos="fade-down" data-aos-duration="1500">COURSES OFFERED</h2>
        <p class="mb-3 text-gray-500 " data-aos="fade-down" data-aos-duration="1500">At PACE,we are committed to providing quality education and empowering our students with knowledge and skills that will prepare them for a bright future. Our college offers a range of specialized courses designed to meet the diverse academic interests and career goals of our students. Currently, we offer two main streams for undergraduate courses.</p>

        <div className='md:flex md:gap-6'>
          <div class="md:w-[50%] px-8 py-4 mt-2 bg-white rounded-lg  border border-gray-700 " data-aos="fade-up" data-aos-duration="1500">
            <div class="mt-2">
              <p class="text-xl font-bold text-gray-700">PCMB</p>
              <p class="text-lg font-bold text-gray-700">(Physics, Chemistry, Mathematics and Biology)</p>
              <p class="mt-2 text-gray-600 "><span className='font-bold'>Duration : </span> 2 years</p>
              <p class="mt-2 text-gray-600 "><span className='font-bold'>Eligibility : </span> SSLC or any equivalent qualifying examinations.</p>
              <p class="mt-2 text-gray-600 "><span className='font-bold'>Description : </span>The PCMB stream is tailored for students who wish to pursue careers in various science-related fields, being not limited to medicine, engineering, biotechnology and pure sciences. This program provides a comprehensive foundation in physics, chemistry, mathematics and biology, allowing students to explore a wider range of scientific disciplines and specializations.</p>
            </div>
          </div>
          <div class="md:w-[50%] px-8 py-4 mt-2 bg-white rounded-lg  border border-gray-700" data-aos="fade-up" data-aos-duration="1500">
            <div class="mt-2">
              <p class="text-xl font-bold text-gray-700">PCMC</p>
              <p class="text-lg font-bold text-gray-700">(Physics, Chemistry, Mathematics and Computer Science)</p>
              <p class="mt-2 text-gray-600 "><span className='font-bold'>Duration : </span> 2 years</p>
              <p class="mt-2 text-gray-600 "><span className='font-bold'>Eligibility : </span> SSLC or any equivalent qualifying examinations.</p>
              <p class="mt-2 text-gray-600 "><span className='font-bold'>Description : </span>The PCMC stream is ideal for students with a keen interest in science and technology, as it integrates the core subjects of physics, chemistry, mathematics and computer science. Graduates from this program are well-equipped to excel in fields such as computer programming, data analysis, engineering and other technology-driven sectors.</p>
            </div>
          </div>
        </div>




        <p class="mb-3 mt-4 text-gray-500 font-bold " data-aos="fade-up" data-aos-duration="1500">Every student has the opportunity to choose two language subjects, with English being compulsory and another language option, such as Kannada, Hindi, or Sanskrit.</p>
        <p class="mb-3 text-gray-500 " data-aos="fade-up" data-aos-duration="1500">Our college strives to maintain a conducive learning environment, foster critical thinking and facilitate holistic development. With experienced faculty, state-of-the-art facilities and a commitment to excellence, we aim to ensure that our students receive the best education possible.</p>
        <p class="mb-3 text-gray-500 " data-aos="fade-up" data-aos-duration="1500">For more information on admission procedures, eligibility criteria, and course details, please feel free to explore our website or contact our admissions department. We look forward to helping you embark on your academic journey at PACE.</p>
      </div>

    </div>
  )
}

export default Courses